import styled, { css } from 'styled-components';

import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import { media } from '../../styleHelpers/breakpoint';

export const MobileMenu = styled.div`
    padding: 0 20px;
    max-width: 320px;
    margin: 0 auto;
    position: relative;
    ${media.tablet`
        display: none;
    `}
`;
export const DesktopMenu = styled.div`
    display: none;
    ${media.tablet`
        display: block;
    `};
`;

export const CounterBox = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    ${media.desktop`
        width: auto;
        height: auto;
    `}
    > img {
        cursor: pointer;
        position: relative;
        z-index:999;
    }
    .pointer {
        cursor: pointer;
    }
`;

export const Counter = styled.div`
    position: absolute;
    top: -5px;
    right: -5px;
    background: ${colorStack.middleRed};
    color: ${colorStack.white};
    font-size: ${fontSize[10]};
    padding: 2px 5px;
    border-radius: 6px;
`;

export const NotificationsListInner = styled.div`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: ${colorStack.whiteGrey};
    font-weight: 600;

`;

export const NotificationsList = styled.div<{width?: number}>`
    position: fixed;
    top: 49px;
    ${props => props.width && css`
        right: ${Math.floor(props.width / 100) - 12}vw;
    `};
    width: 490px;
    padding: 0 0 0 0;
`;

export const NotificationsShowAll = styled.div`
    font-size: ${fontSize[16]};
    text-align: center;
    border-bottom: 3px solid ${colorStack.middleBlue};
    width: 100%;
    padding: 9px 0;
    cursor: pointer;
    color: ${colorStack.middleBlue};
    span {
        font-size: inherit!important;
    }
`;
