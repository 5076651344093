export const fontSize = {
    10: '0.684rem', // === 10.944px
    13: '0.887rem', // === 14.192px
    16: '1.0rem', // === 16px
    20: '1.322rem', // === 21.152px
    25: '1.656rem', // === 26.496px
    31: '1.988rem', // === 31.808px
    39: '2.500rem', // === 40.000px
    49: '3.114rem', // === 49.824px
    61: '3.891rem', // === 62.256px
    76: '4.785rem', // === 76.560px
    95: '6.090rem'  // === 97.440px
};