import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { faXmark, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { colorStack } from '../../../styleHelpers/colors';
import { Avatar } from '../Avatar/Avatar';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { IProfileType } from '../../../entities/IGlobal';
import { ISingleGroupUser } from '../../../entities/IAdmin';
import { TextWithEllipsis } from '../TextWithEllipsis/TextWithEllipsis';
import { ConfirmPopup } from '../../Common/ConfirmPopup/ConfirmPopup';
import * as adminActions from '../../../actions/adminActions';
import { isFontAwesomeIcon } from '../../../tools/generalTools';

type GetSingleGroup = ReturnType<typeof adminActions.getSingleGroup>;

const Wrapper = styled.div<{ numberOfIcons: number, type: string, bgColor?: string, fontColor?: string, withoutMargins: boolean, disabled?: boolean, rightPadding?: boolean, leftPadding?: boolean }>`
    background: ${props => props.bgColor};
    color: ${props => props.fontColor};
    max-width: 100%;
    padding: 0 16px;
    display: inline-flex;
    white-space: nowrap;
    border-radius: 50px;
    margin: 0 0.5rem 0.5rem 0;
    align-items: center;
    height: 32px;
    ${props => props.type === 'company' && css`
        border-radius: 8px;
    `}
    ${props => props.type === 'duo' && css`
        background: ${colorStack.white};
        padding: 0;
    `}
    ${props => props.withoutMargins && css`
        margin: 0;
    `}
    ${props => props.rightPadding && css`
        padding-right: 4px;
    `}
    ${props => props.leftPadding && css`
        padding-left: 4px;
    `}
    ${props => props.disabled && css`
        background: ${colorStack.whiteGrey};
        color: ${colorStack.disabled};
        svg {
            color: ${colorStack.disabled};
        }
    `}
    ${props => props.type === 'duo' && css`
        padding: 0;
    `}
    > span, > a {
        width: 100%;
        margin: 0 .5rem;
        ${props => (props.numberOfIcons) && css`
            width: ${`calc(100% - ${props.numberOfIcons * 24}px - 1rem)`};
        `}
    }
`;

const Text = styled.span`
    font-size: ${fontSize[16]};
    width: 100%;
`;

const GroupElement = styled.div`
    cursor: pointer;
`;

const GroupPopupContainer = styled.div`
    display: grid;
    grid-template-columns: 10rem 10rem 10rem;
    grid-column-gap: 8px;
`;

const GroupPopupElement = styled.div`
    padding-bottom: .8rem;
`;

const CloseBox = styled.div<{ iconColor: string }>`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    background: transparent;
    transition: .3s;
    &:hover {
        background: ${colorStack.ligthGrey};
    }
    svg {
        ${props => props.iconColor && css`
            color: ${props.iconColor};
        `}
    }
`;

const IcoBox = styled.div<{ iconColor: string }>`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    svg {
        ${props => props.iconColor && css`
            color: ${props.iconColor};
        `}
    }
`;

const DuoBox = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
`;

const LeftSide = styled.div`
    border-radius: 32px 0 0 32px;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
`;

const RightSide = styled.div<{ bgColor: string }>`
    background: ${props => props.bgColor};
    border-radius: 0 32px 32px 0;
    padding: 0 4px 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    > span {
        margin-right: .5rem;
    }
`;

interface IButtonIconProps {
    ico: React.ReactNode | IconDefinition
    iconColor: string;
}

const ButtonIcon = ({ ico, iconColor }: IButtonIconProps) => ico ?
    <IcoBox iconColor={iconColor}>
        {isFontAwesomeIcon(ico) ? <FontAwesomeIcon icon={ico} /> : ico}
    </IcoBox> : null; // tslint:disable-line: no-null-keyword

interface CommonProps {
    elemId: string;
    bgColor?: string;
    fontColor?: string;
    iconColor?: string;
    translationKey?: string;
    withoutMargins?: boolean;
    disabled?: boolean;
    link?: string;
    onClick?(elemId: string);
}

type ConditionalProps =
    | {
        type: 'default';
        text: string;
        rightIco?: React.ReactNode | IconDefinition;
        leftIco?: React.ReactNode | IconDefinition;
    }
    | {
        type: 'duo';
        text: string;
        value: string;
        rightIco?: React.ReactNode | IconDefinition;
        leftIco?: React.ReactNode | IconDefinition;
    }
    | {
        type: IProfileType.Personal | IProfileType.Company | IProfileType.Group;
        firstName: string;
        id: string;
        picture: string;
        lastName?: string;
    };

type IProps = CommonProps & ConditionalProps;

export const Pill: FC<IProps> = props => {

    const dispatch = useDispatch();
    const [isGroupPopupVisible, setIsGroupPopupVisible] = useState<boolean>(false);
    const [groupData, setGroupData] = useState<ISingleGroupUser[]>(undefined);
    const [numberOfIcons, setNumberOfIcons] = useState<number>(0);

    useEffect(() => {
        if (props.type === 'default' && props.leftIco) setNumberOfIcons(state => ++state);
        if (props.type === 'default' && props.rightIco) setNumberOfIcons(state => ++state);
        if (props.onClick) setNumberOfIcons(state => ++state);
    }, []);

    const clickHandler = useCallback(() => {
        !props.disabled && props.onClick(props.elemId);
    }, [props.elemId, props.onClick, props.disabled]);

    const clickGroupHandler = useCallback((id: string) => {
        dispatch<GetSingleGroup>(adminActions.getSingleGroup(id)).then(response => {
            setGroupData(response?.groupUsers);
            setIsGroupPopupVisible(state => !state);
        });
    }, []);

    return (
        <Wrapper
            type={props.type}
            bgColor={props.bgColor || colorStack.whiteBlue}
            fontColor={props.fontColor || colorStack.content}
            withoutMargins={props.withoutMargins}
            disabled={props.disabled}
            rightPadding={!!props.onClick}
            numberOfIcons={numberOfIcons}
            leftPadding={!!(props.type !== 'default' || props.leftIco)}
        >
            {props.type === 'duo' &&
                <DuoBox>
                    <LeftSide>{props.text}</LeftSide>
                    <RightSide bgColor={props.bgColor || colorStack.whiteBlue}>
                        <span>{props.value}</span>
                        {props.onClick &&
                            <CloseBox onClick={clickHandler} iconColor={props.iconColor || colorStack.darkGrey}>
                                <FontAwesomeIcon icon={faXmark} />
                            </CloseBox>
                        }
                    </RightSide>
                </DuoBox>
            }
            {props.type === 'default' &&
                <ButtonIcon ico={props.leftIco} iconColor={props.iconColor || colorStack.darkGrey} />
            }
            {props.link ? (
                <a href={props.link} target="_blank">
                    {props.type === 'default' && <Text><TextWithEllipsis>{props.translationKey ? <FormattedMessage id={`${props.translationKey}.${props.text}`} /> : props.text}</TextWithEllipsis></Text>}
                </a>
            ) : (
                <>
                    {props.type === 'default' && <Text><TextWithEllipsis>{props.translationKey ? <FormattedMessage id={`${props.translationKey}.${props.text}`} /> : props.text}</TextWithEllipsis></Text>}
                </>
            )}
            {(props.type === IProfileType.Personal || props.type === IProfileType.Company) &&
                <Avatar
                    firstName={props.firstName}
                    lastName={props.lastName}
                    type={props.type as IProfileType}
                    id={props.id}
                    picture={props.picture}
                    extended
                    size={{
                        width: 24,
                        height: 24
                    }}
                />
            }
            {props.type === IProfileType.Group && (
                <GroupElement onClick={() => clickGroupHandler(props?.id)}>
                    <Avatar
                        firstName={props.firstName}
                        lastName={props.lastName}
                        type={props.type as IProfileType}
                        id={props.id}
                        picture={props.picture}
                        extended
                        preventRedirection
                        size={{
                            width: 24,
                            height: 24
                        }}
                    />
                </GroupElement>
            )}
            {props.type === 'default' &&
                <ButtonIcon ico={props.rightIco} iconColor={props.iconColor || colorStack.darkGrey} />
            }
            {(props.onClick && props.type !== 'duo') &&
                <CloseBox onClick={clickHandler} iconColor={props.iconColor || colorStack.darkGrey}>
                    <FontAwesomeIcon icon={faXmark} />
                </CloseBox>
            }
            <ConfirmPopup
                showPopup={isGroupPopupVisible}
                noHandler={() => setIsGroupPopupVisible(false)}
                noButtonText={<FormattedMessage id="global.close" />}
            >
                <GroupPopupContainer>
                    {groupData && groupData?.map(el => (
                        <GroupPopupElement key={el?.id}>
                            <Avatar
                                firstName={el.name}
                                lastName={''}
                                type={IProfileType?.Personal}
                                id={el.id}
                                picture={el.picture}
                                extended
                                size={{
                                    width: 24,
                                    height: 24
                                }}
                            />
                        </GroupPopupElement>
                    ))}
                </GroupPopupContainer>
            </ConfirmPopup>
        </Wrapper>
    );
};