import { combineReducers } from 'redux';

import config from './configReducer';
import context from './contextReducer';
import rms from './rmsReducer';
import profile from './profileReducer';
import cookies from './cookiesReducer';
import company from './companyReducer';
import organization from './organizationReducer';
import clusters from './clustersReducer';
import onboarding from './onboardingReducer';
import expertise from './expertiseReducer';
import fileRepository from './fileRepositoryReducer';
import notifications from './notificationReducer';
import rfp from './rfpReducer';
import proposal from './proposalReducer';
import recommendation from './recommendationsReducer';
import messaging from './messagingReducer';
import searchHandle from './searchReducer';
import language from './languageReducer';
import admin from './adminReducer';
import panels from './panelsReducer';
import soge from './sogeReducer';
import alert from './alertReducer';
import legalEntities from './legalEntitiesReducer';
import stake from './stakeReducer';
import menu from './menuReducer';
import analytics from './analyticsReducer';
import avatar from './avatarReducer';
import work from './workReducer';
import explore from './exploreReducer';
import pdfOverlay from './pdfOverlayReducer';
import global from './globalReducer';

const store = combineReducers({
    config,
    context,
    rms,
    profile,
    company,
    cookies,
    organization,
    onboarding,
    expertise,
    fileRepository,
    notifications,
    rfp,
    proposal,
    recommendation,
    messaging,
    searchHandle,
    language,
    clusters,
    admin,
    panels,
    soge,
    alert,
    legalEntities,
    stake,
    menu,
    analytics,
    avatar,
    work,
    explore,
    pdfOverlay,
    global
});

export type IState = ReturnType<typeof store>;

export default store;
