export const NEW_MESSAGE_VIEW = 'NEW_MESSAGE_VIEW';
export const CLEAR_MESSAGING_STATE = 'CLEAR_MESSAGING_STATE';
export const SHOW_DOTS_MENU = 'SHOW_DOTS_MENU';
export const SHOW_SEARCH_MENU = 'SHOW_SEARCH_MENU';
export const SHOW_SETTINGS_MENU = 'SHOW_SETTINGS_MENU';
export const SET_MESSAGING_CONTACTS_REQUESTED = 'SET_MESSAGING_CONTACTS_REQUESTED';
export const SET_MESSAGING_CONTACTS_COMPLETED = 'SET_MESSAGING_CONTACTS_COMPLETED';
export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
export const SEARCH_CONVERSATIONS = 'SEARCH_CONVERSATIONS';
export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS';
export const PENDING_SEARCH_CONVERSATIONS = 'PENDING_SEARCH_CONVERSATIONS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const ADD_TEMP_GROUP = 'ADD_TEMP_GROUP';
export const ACTIVE_CONVERSATION_ID = 'ACTIVE_CONVERSATION_ID';
export const CURRENT_MESSAGES_REQUESTED = 'CURRENT_MESSAGES_REQUESTED';
export const CURRENT_MESSAGES_COMPLETED = 'CURRENT_MESSAGES_COMPLETED';
export const CLEAR_CURRENT_MESSAGES = 'CLEAR_CURRENT_MESSAGES';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
export const SET_NEW_CONVERSATION = 'SET_NEW_CONVERSATION';
export const MESSAGE_NOT_SEND = 'MESSAGE_NOT_SEND';
export const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION';
export const MARK_CONVERSATION_AS_READ = 'MARK_CONVERSATION_AS_READ';
export const UPDATE_SINGLE_MESSAGE = 'UPDATE_SINGLE_MESSAGE';
export const COMPLETE_UPLOAD_ATTACHMENT = 'COMPLETE_UPLOAD_ATTACHMENT';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const REMOVE_TEMP_CONVERSATION = 'REMOVE_TEMP_CONVERSATION';
export const ADD_RECEIVE_ATTACHMENT = 'ADD_RECEIVE_ATTACHMENT';
export const GET_UNREAD_CONVERSATIONS = 'GET_UNREAD_CONVERSATIONS';
export const ADD_UNREAD_CONVERSATION = 'ADD_UNREAD_CONVERSATION';
export const USER_REMOVED_FROM_CONVERSATION = 'USER_REMOVED_FROM_CONVERSATION';
export const SET_CONVERSATION_MENU = 'SET_CONVERSATION_MENU';
export const SET_CONVERSATION_MENU_EXPANDED = 'SET_CONVERSATION_MENU_EXPANDED';
export const ADD_ACTIVE_CONVERSATION = 'ADD_ACTIVE_CONVERSATION';
export const ADD_ACTIVE_CONVERSATION_MESSAGES = 'ADD_ACTIVE_CONVERSATION_MESSAGES';
export const REMOVE_ACTIVE_CONVERSATION = 'REMOVE_ACTIVE_CONVERSATION';
export const SET_ACTIVE_CONVERSATION_VISIBILITY = 'SET_ACTIVE_CONVERSATION_VISIBILITY';