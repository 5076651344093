import * as actionTypes from '../actions/actionTypes/messagingTypes';
import { ISingleMessage, ISingleConversation, Contact } from '../entities/Messaging/IMessaging';

export interface IMessagingReducer {
    newMessageView: boolean;
    showSearchMenu: boolean;
    showSettingsMenu: boolean;
    showDotsMenu: boolean;
    messagingContactsLoading: boolean;
    messagingContacts: Contact[];
    messagingContactsCount: number;
    conversations: ISingleConversation[];
    currentConversation: ISingleConversation; // use only for window view
    activeConversations: {
        [id: string]: {
            conversation: ISingleConversation;
        }
    };
    showConversationMenu: boolean;
    conversationsExpandend: boolean;
    conversationsLoaded: boolean;
    conversationsCount: number;
    currentMessages: ISingleMessage[];
    currentMessagesLoaded: boolean;
    currentMessagesCount: number;
    conversationId: string;
    activeConversationId: string;
    unreadConversations: string[];
    itemsOnPageCount: number;
    searchConversationsLoaded: boolean;
}

const defaultState = (): IMessagingReducer => ({
    newMessageView: false,
    showSearchMenu: false,
    showSettingsMenu: false,
    showDotsMenu: false,
    currentConversation: undefined,
    currentMessages: undefined,
    currentMessagesLoaded: true,
    currentMessagesCount: 0,
    activeConversationId: undefined,
    messagingContacts: [],
    messagingContactsCount: 0,
    conversationsCount: 0,
    messagingContactsLoading: true,
    conversations: [],
    conversationsLoaded: false,
    conversationId: undefined,
    unreadConversations: [],
    itemsOnPageCount: 20,
    searchConversationsLoaded: false,
    activeConversations: undefined,
    showConversationMenu: false,
    conversationsExpandend: false
});

export default (state = defaultState(), action): IMessagingReducer => {
    switch (action.type) {
        case actionTypes.NEW_MESSAGE_VIEW: {
            return {
                ...state,
                newMessageView: !state.newMessageView
            };
        }
        case actionTypes.SHOW_DOTS_MENU: {
            return {
                ...state,
                showDotsMenu: !state.showDotsMenu
            };
        }
        case actionTypes.CREATE_MESSAGE: {
            return {
                ...state,
                conversationId: state.conversationId
            };
        }
        case actionTypes.CURRENT_MESSAGES_COMPLETED: {
            return {
                ...state,
                currentMessages: (action.currentMessages || []).sort((a: ISingleMessage, b: ISingleMessage) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()),
                currentMessagesCount: action.currentMessagesCount,
                currentMessagesLoaded: true
            };
        }
        case actionTypes.CURRENT_MESSAGES_REQUESTED: {
            return {
                ...state,
                currentMessagesLoaded: false
            };
        }
        case actionTypes.CLEAR_CURRENT_MESSAGES: {
            return {
                ...state,
                currentMessages: undefined,
                currentMessagesCount: 0
            };
        }
        case actionTypes.CLEAR_MESSAGING_STATE: {
            return {
                ...state,
                currentMessages: undefined,
                conversations: [],
                activeConversationId: undefined,
                conversationsLoaded: false,
                currentConversation: undefined
            };
        }
        case actionTypes.MARK_CONVERSATION_AS_READ: {
            return {
                ...state,
                conversations: state.conversations.map((elem) => elem.id === action.conversationsId ? {
                    ...elem,
                    unreadMessagesCount: 0
                } : elem),
                unreadConversations: state.unreadConversations.filter(conversationId => conversationId !== action.conversationsId)
            };
        }
        case actionTypes.SET_NEW_MESSAGE: {
            const currentMessageObj = action.currentMessage.type === 'Attachment' ? {
                ...action.currentMessage,
                attachments: [{
                    filteType: '',
                    name: '',
                    size: '',
                    uri: ''
                }]
            } : action.currentMessage || [];
            return {
                ...state,
                currentMessages: (action.currentMessage)
                    ? ([...state.currentMessages || [], currentMessageObj])
                        .sort((a: ISingleMessage, b: ISingleMessage) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())
                    : [...currentMessageObj]
            };
        }
        case actionTypes.MESSAGE_NOT_SEND: {
            return {
                ...state,
                currentMessages: state.currentMessages.map((message: any) => message.id === action.notSendMessageId ? {
                    ...message,
                    status: 'Failed'
                } : message)
            };
        }
        case actionTypes.REMOVE_MESSAGE: {
            return {
                ...state,
                currentMessages: state.currentMessages.filter((message: any) => message.id !== action.messageId)
            };
        }
        case actionTypes.UPDATE_SINGLE_MESSAGE: {
            const currentMessageObj = action.message.type === 'Attachment' ? {
                ...action.message,
                attachments: [{
                    filteType: '',
                    name: '',
                    size: '',
                    uri: ''
                }]
            } : action.message;
            return {
                ...state,
                currentMessages: state.currentMessages ? state.currentMessages.map((message: any) => message.id === action.message.tempId ? currentMessageObj : message) : []
            };
        }
        case actionTypes.ADD_RECEIVE_ATTACHMENT: {
            return {
                ...state,
                currentMessages: state.currentMessages ? state.currentMessages.map((message: any) => message.id === action.data.messageId ? {
                    ...message,
                    attachments: [action.data.file]
                } : message) : []
            };
        }
        case actionTypes.COMPLETE_UPLOAD_ATTACHMENT: {
            return {
                ...state,
                currentMessages: state.currentMessages.map((elem: any) => elem.id === action.attachmentInfo.messageId ? {
                    ...elem,
                    attachments: [action.attachmentInfo.fieAttachment]
                } : elem)
            };
        }
        case actionTypes.SHOW_SEARCH_MENU: {
            return {
                ...state,
                showSearchMenu: !state.showSearchMenu,
                showSettingsMenu: false,
                showDotsMenu: false
            };
        }
        case actionTypes.SHOW_SETTINGS_MENU: {
            return {
                ...state,
                showSettingsMenu: !state.showSettingsMenu,
                showSearchMenu: false,
                showDotsMenu: false
            };
        }
        case actionTypes.SET_MESSAGING_CONTACTS_REQUESTED: {
            return {
                ...state,
                messagingContactsLoading: true
            };
        }
        case actionTypes.SET_MESSAGING_CONTACTS_COMPLETED: {
            return {
                ...state,
                messagingContacts: action.append ? [...state.messagingContacts, ...action.contacts] : action.contacts,
                messagingContactsCount: action.count,
                messagingContactsLoading: action.loading || false
            };
        }
        case actionTypes.CLEAR_CONVERSATIONS: {
            return {
                ...state,
                conversations: undefined
            };
        }
        case actionTypes.SET_CONVERSATION_MENU: {
            return {
                ...state,
                showConversationMenu: action.value,
                conversationsExpandend: typeof action.expanded !== 'undefined' ? action.expanded : state.conversationsExpandend
            };
        }
        case actionTypes.SET_CONVERSATION_MENU_EXPANDED: {
            return {
                ...state,
                conversationsExpandend: action.expanded
            };
        }
        case actionTypes.SET_CURRENT_CONVERSATION: {
            return {
                ...state,
                currentConversation: action.currentConversation,
                activeConversationId: action.currentConversationId
            };
        }
        case actionTypes.SEARCH_CONVERSATIONS: {
            return {
                ...state,
                conversations: action.spread ? [...(state.conversations || []), ...(action.conversations?.items || [])] : (action.conversations?.items || []),
                currentConversation: state.currentConversation || action.conversations?.items[0],
                conversationsCount: action.conversations?.count,
                conversationsLoaded: true,
                searchConversationsLoaded: true
            };
        }
        case actionTypes.PENDING_SEARCH_CONVERSATIONS: {
            return {
                ...state,
                searchConversationsLoaded: false
            };
        }
        case actionTypes.UPDATE_CONVERSATION: {
            return {
                ...state,
                currentConversation: action.singleConversation,
                conversations: (state.conversations.find((conversation: ISingleConversation) => conversation.id === action.singleConversation.id) ?
                    state.conversations.map((elem) => elem.id === action.singleConversation.id ?
                        action.singleConversation :
                        elem) :
                    [action.singleConversation, ...state.conversations]).sort((a, b) => (new Date((a.lastMessage && a.lastMessage.creationDate) || a.creationDate).getTime() > new Date((b.lastMessage && b.lastMessage.creationDate) || b.creationDate).getTime()) ? -1 : ((new Date((b.lastMessage && b.lastMessage.creationDate) || b.creationDate).getTime() > new Date((a.lastMessage && a.lastMessage.creationDate) || a.creationDate).getTime()) ? 1 : 0))
            };
        }
        case actionTypes.SET_NEW_CONVERSATION: {
            return {
                ...state,
                conversations: [action.conversation, ...state.conversations]
            };
        }
        case actionTypes.ACTIVE_CONVERSATION_ID: {
            return {
                ...state,
                activeConversationId: action.activeConversationId
            };
        }
        case actionTypes.REMOVE_CONVERSATION: {
            return {
                ...state,
                activeConversationId: state.conversations[0].id
            };
        }
        case actionTypes.REMOVE_TEMP_CONVERSATION: {
            return {
                ...state,
                conversations: (state.conversations || []).filter((conversation: ISingleConversation) => conversation.id !== action.removeConverastionId),
                activeConversationId: state.conversations[0].id
            };
        }
        case actionTypes.ADD_TEMP_GROUP: {
            return {
                ...state,
                conversations: [action.newGroup, ...(state.conversations || [])],
                currentConversation: action.newGroup,
                newMessageView: false,
                activeConversationId: action.newGroup.id,
                currentMessages: undefined
            };
        }
        case actionTypes.GET_UNREAD_CONVERSATIONS: {
            return {
                ...state,
                unreadConversations: action.unreadConversations || []
            };
        }
        case actionTypes.ADD_UNREAD_CONVERSATION: {
            return {
                ...state,
                unreadConversations: state.unreadConversations.indexOf(action.conversationId) === -1 && action.conversationId !== state.activeConversationId
                    ? [...state.unreadConversations, action.conversationId]
                    : state.unreadConversations
            };
        }
        case actionTypes.USER_REMOVED_FROM_CONVERSATION: {
            return {
                ...state,
                conversations: state.conversations.filter((conversation: ISingleConversation) => conversation.id !== action.conversationId),
                conversationsCount: state.conversationsCount - 1
            };
        }
        case actionTypes.ADD_ACTIVE_CONVERSATION: {
            return {
                ...state,
                activeConversations: {
                    ...state.activeConversations,
                    [action.conversationId]: {
                        conversation: action.conversation
                    }
                }
            };
        }
        case actionTypes.REMOVE_ACTIVE_CONVERSATION: {
            return {
                ...state,
                activeConversations: {
                    ...Object.keys(state.activeConversations)
                        .filter(conversationId => conversationId !== action.conversationId)
                        .reduce((conversations, conversationId) => ({
                            ...conversations,
                            [conversationId]: state.activeConversations[conversationId]
                        }), {})
                }
            };
        }
        default: {
            return state;
        }
    }
};