import { IClusterTemplateFacet, ISingleWorkElem } from '../../entities/IWork';

export const GET_WORKS_ELEMENTS = 'GET_WORKS_ELEMENTS';


export interface IWorkTypes {
    [GET_WORKS_ELEMENTS]: {
            workResult: ISingleWorkElem[];
            templates: IClusterTemplateFacet[];
            itemsCount: number;
    }
}
