import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { saveAs } from 'file-saver';
import { Dispatch } from 'redux';
import { get } from 'lodash';
import queryString from 'query-string-for-all';

import { action } from '../tools/reduxTools';
import { Http } from '../tools/http';
import { getContextUserId, isIdTokenExpired } from '../tools/authTools';
import * as actionTypes from './actionTypes/messagingTypes';
import { ISingleMessage, IAttachmentInfo, ISingleConversation, IAttachment, MessageType, IMessageSearchResult, Contact, TriggerType } from '../entities/Messaging/IMessaging';
import { ISearchResponse } from '../entities/IGlobal';
import { IState } from '../reducers';
import { randomSecureNumber } from '../tools/generalTools';

let apiCancelSource: () => void;

export const changeMessageView = () => ({
    type: actionTypes.NEW_MESSAGE_VIEW
});

export const showSearchMenuFunc = () => ({
    type: actionTypes.SHOW_SEARCH_MENU
});

export const showSettingsMenuFunc = () => ({
    type: actionTypes.SHOW_SETTINGS_MENU
});

export const showDotsMenuFunc = () => ({
    type: actionTypes.SHOW_DOTS_MENU
});

export const clearCurrentMessages = () => ({
    type: actionTypes.CLEAR_CURRENT_MESSAGES
});

export const setActiveConversation = (activeConversationId: string) => (dispatch, getState, http: Http) => {
    dispatch({
        type: actionTypes.ACTIVE_CONVERSATION_ID,
        activeConversationId
    });
    /* dispatch(getMessages()); */

};

export const setNewMessage = (currentMessage: ISingleMessage) => ({
    type: actionTypes.SET_NEW_MESSAGE,
    currentMessage
});

export const setNewConversation = (conversation: ISingleConversation) => ({
    type: actionTypes.SET_NEW_CONVERSATION,
    conversation
});

export const updateSingleMessage = (message: ISingleMessage) => ({
    type: actionTypes.UPDATE_SINGLE_MESSAGE,
    message
});

export const clearMessagingState = () => ({
    type: actionTypes.CLEAR_MESSAGING_STATE
});

export const removeTempConversation = (removeConverastionId: string) => ({
    type: actionTypes.REMOVE_TEMP_CONVERSATION,
    removeConverastionId
});

export const updateSingleConversation = (singleConversation: ISingleConversation) => ({
    type: actionTypes.UPDATE_CONVERSATION,
    singleConversation
});

export const removeDoubleMessage = (messageId: string) => ({
    type: actionTypes.REMOVE_MESSAGE,
    messageId
});

export const addReceiveAttachment = (messageId: string, file: IAttachment) => ({
    type: actionTypes.ADD_RECEIVE_ATTACHMENT,
    data: {
        messageId,
        file
    }
});

export const downloadMessagingFileAsBlob = (uri: string, isThumbnail: boolean) => ((dispatch, getState, http: Http) => {
    const { context, config } = getState();
    return fetch(`${config.messagingApiUrl}/Attachments/download`, {
        method: 'POST',
        body: JSON.stringify({ uri, isThumbnail }),
        headers: {
            'Authorization': `Bearer ${context.token}`,
            'Ocp-Apim-Subscription-Key': config.subKey,
            'Content-Type': 'application/json'
        }
    }).then(res => res.blob());
}) as any;

export const downloadAttachment = (uri: string, fileName: string) => ((dispatch, getState, http: Http) => {
    const { context, config } = getState();
    return fetch(`${config.messagingApiUrl}/Attachments/download`, {
        method: 'POST',
        body: JSON.stringify({ uri }),
        headers: {
            'Authorization': `Bearer ${context.token}`,
            'Ocp-Apim-Subscription-Key': config.subKey,
            'Content-Type': 'application/json'
        }
    }).then(res => {
        return res.blob();
    }).then(blob => {
        blob && saveAs(blob, fileName);
    });
}) as any;

export const uploadAttachment = (messageId: string, file: any) => ((dispatch, getState, http: Http) => {
    const formData = new FormData();
    formData.append('attachment', file, file.name);
    return http.messagingApiPost(`/Attachments/upload/${messageId}`, formData)
        .then((attachmentInfo: IAttachmentInfo) => {
            dispatch({
                type: actionTypes.COMPLETE_UPLOAD_ATTACHMENT,
                attachmentInfo
            });
        });
}) as any;

export const changeGroupTitle = (title: string, resourceChildId: string, teamId: string, teamChannelId: string) => ((dispatch, getState, http: Http) => {
    const { messaging } = getState();
    return http.messagingApiPut(`/resources/${resourceChildId}/teams/${teamId}/channels/${teamChannelId}/conversations/${messaging.activeConversationId}/title-update?title=${title}`)
        .then(res => {
            return res;
        });
}) as any;

export const markAsRead = (messageId: string, conversationId?: string) => action<Promise<unknown>, IState>((dispatch, getState, http) => {
    const { messaging } = getState();
    const activeConversationId = conversationId || messaging.activeConversationId;

    if (messageId !== '0' && activeConversationId) {
        return http.messagingApiPost(`/Conversations/${activeConversationId}/messages/${messageId}/read`)
            .then(res => {
                dispatch({
                    type: actionTypes.MARK_CONVERSATION_AS_READ,
                    conversationsId: res.conversationId
                });
                return res;
            });
    }
});

export const removeMemberFromConversation = (userId: string) => ((dispatch, getState, http: Http) => {
    const { messaging } = getState();
    return http.messagingApiDelete(`/Conversations/${messaging.activeConversationId}/members/${userId}`)
        .then(singleConversation => {
            if (singleConversation.conversationMembers && singleConversation.conversationMembers.length > 0) {
                dispatch({
                    type: actionTypes.UPDATE_CONVERSATION,
                    singleConversation
                });
            } else {
                dispatch({
                    type: actionTypes.REMOVE_CONVERSATION
                });
            }
        });
}) as any;

export const userRemovedFromConversation = (conversationId: string) => ({
    type: actionTypes.USER_REMOVED_FROM_CONVERSATION,
    conversationId
});

export const removeMesssage = (messageId: string, conversationId?: string) => ((dispatch, getState, http: Http) => {
    const { messaging } = getState();
    return http.messagingApiDelete(`/Messages?messageId=${messageId}&conversationId=${conversationId || messaging.activeConversationId}`);
}) as any;

export const getMessages = (size: number = 50, resourceChildId: string, teamId: string, teamChannelId: string, activeConversationId?: string, page?: number, callbackOnly?: boolean) => ((dispatch, getState: () => IState, http: Http) => {
    const { messaging } = getState();

    !callbackOnly && dispatch({ type: actionTypes.CURRENT_MESSAGES_REQUESTED });

    const conversationId = activeConversationId || messaging.activeConversationId;

    if (!conversationId) {
        return;
    }

    return http.messagingApiGet(`/resources/${resourceChildId}/teams/${teamId}/channels/${teamChannelId}/conversations/${conversationId}/messages?page=${page || 0}&size=${size}`)
        .then((result: ISearchResponse<ISingleMessage>) => {
            !callbackOnly && dispatch({
                type: actionTypes.CURRENT_MESSAGES_COMPLETED,
                currentMessages: result?.items || [],
                currentMessagesCount: result?.count
            });
            return result;
        });
}) as any;

export const loadNewMessagePage = (page: number = 0, size: number = 50) => ((dispatch, getState: () => IState, http: Http) => {
    const { messaging } = getState();

    dispatch({ type: actionTypes.CURRENT_MESSAGES_REQUESTED });

    return http.messagingApiGet(`/Conversations/${messaging.activeConversationId}/messages?page=${page}&size=${size}`)
        .then(result => {
            const newMessages = get(result, 'items', []) || [];

            dispatch({
                type: actionTypes.CURRENT_MESSAGES_COMPLETED,
                currentMessages: [...newMessages, ...messaging.currentMessages],
                currentMessagesCount: get(result, 'count', 0)
            });
        });
}) as any;

export const getMessagingContacts = (query?: string, page?: number, append?: boolean) => ((dispatch, getState: () => IState, http: Http) => {
    dispatch({ type: actionTypes.SET_MESSAGING_CONTACTS_REQUESTED });

    return http.apiGet(`/messaging/contacts?search=${query || ''}*&page=${page || 0}`, undefined, (cancel) => apiCancelSource = cancel)
        .then(response => {
            dispatch(setMessagingContacts((response && response.Items) || [], (response && response.Count) || 0, false, page, append));
        });
}) as any;

export const setMessagingContacts = (contacts?: Contact[], count?: number, loading?: boolean, page?: number, append?: boolean) => {
    apiCancelSource && apiCancelSource();
    return {
        type: actionTypes.SET_MESSAGING_CONTACTS_COMPLETED,
        contacts: contacts || [],
        count: contacts ? (count || 0) : 0,
        loading,
        page: page || 0,
        append
    };
};

export const createMessages = (body: ISingleMessage, setActiveConversationId = true) => ((dispatch, getState, http: Http) => { // should be body: ICreateMessage
    return http.messagingApiPost('/Messages', body)
        .then(res => {
            setActiveConversationId && dispatch({
                type: actionTypes.ACTIVE_CONVERSATION_ID,
                activeConversationId: res.conversationId
            });
            return res;
        }).catch(() => {
            dispatch(setNewMessage(body));
            dispatch({
                type: actionTypes.MESSAGE_NOT_SEND,
                notSendMessageId: body.id
            });
        });
}) as any;

export const addMembersToConversation = (body: any, conversationId: string) => ((dispatch, getState, http: Http) => {
    return http.messagingApiPost(`/conversations/${conversationId}/members`, body)
        .then((singleConversation: ISingleConversation) => {
            dispatch({
                type: actionTypes.UPDATE_CONVERSATION,
                singleConversation
            });
            /* dispatch(getMessages()); */
        });
}) as any;

export const createTempGroup = (conversationMembers: any, conNum: number | string, currentUserName: any) => ((dispatch, getState, http: Http) => {
    dispatch({
        type: actionTypes.ADD_TEMP_GROUP,
        newGroup: {
            content: '',
            type: 0,
            title: `${currentUserName} group`,
            conversationId: '',
            id: `tempGroup-${conNum}`,
            conversationMembers,
            unreadMessagesCount: 0,
            lastMessage: {
                creationDate: new Date().toISOString(),
                messageId: '',
                preview: ''
            }
        }
    });
}) as any;

export const getConversation = (conversationId: string, resourceChildId: string, teamId: string, teamChannelId: string): Promise<ISingleConversation> => ((dispatch, getState, http: Http) => {
    return http.messagingApiGet(`/resources/${resourceChildId}/teams/${teamId}/channels/${teamChannelId}/conversations/${conversationId}`);
}) as any;

export const AddOwnership = (memberId: string) => ((dispatch, getState, http: Http) => {
    const { messaging } = getState();
    return http.messagingApiPut(`/conversations/${messaging.activeConversationId}/members/${memberId}/set-ownership`)
        .then(res => {
            dispatch(updateSingleConversation(res));
            return res;
        });
}) as any;

export const RevokeOwnership = (memberId: string) => ((dispatch, getState, http: Http) => {
    const { messaging } = getState();
    return http.messagingApiPut(`/conversations/${messaging.activeConversationId}/members/${memberId}/revoke-ownership`)
        .then(res => {
            dispatch(updateSingleConversation(res));
            return res;
        });
}) as any;

export const searchConversation = (query: string, messagType: MessageType, page: number = 0, size: number = 10): Promise<IMessageSearchResult> => ((dispatch, getState, http: Http) => {
    const { messaging } = getState();
    const requestQuery = [
        `/conversations/${messaging.activeConversationId}/messages/search?`,
        `${query ? `searchQuery=${encodeURIComponent(query)}` : ''}`,
        `${!!messagType ? `&messageType=${messagType}` : ''}`,
        `&page=${page}&size=${size}`
    ].join('');
    return http.messagingApiGet(requestQuery);
}) as any;

export const getMessageWithNearest = (messageId: string, before: number = 20, after: number = 20) => ((dispatch, getState, http: Http) => {
    const { messaging } = getState();
    return http.messagingApiGet(`/conversations/${messaging.activeConversationId}/messages/${messageId}/with-nearest?before=${before}&after=${after}`);
}) as any;

export const getUnreadConversations = (resourceChildId: string, teamId: string, teamChannelId: string): Promise<string[]> => ((dispatch: Dispatch, getState, http: Http) => {
    return http.messagingApiGet(`/resources/${resourceChildId}/teams/${teamId}/channels/${teamChannelId}/conversations/my/unread-ids`).then((response: string[]) => {
        dispatch({
            type: actionTypes.GET_UNREAD_CONVERSATIONS,
            unreadConversations: response instanceof Array ? response : []
        });

        return response;
    });
}) as any;

export const addUnreadConversation = (conversationId: string) => ({
    type: actionTypes.ADD_UNREAD_CONVERSATION,
    conversationId
});

export const createSignlaRHubConnection = (signalRUri: string, token: string): HubConnection => ((dispatch, getState, http: Http) => {
    http.signalRConnection = new HubConnectionBuilder()
        .withUrl(`${signalRUri}`, { accessTokenFactory: () => token })
        .configureLogging(LogLevel.Warning)
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: () => {
                if (!isIdTokenExpired(getContextUserId())) {
                    return randomSecureNumber() * 10000;
                } else {
                    // tslint:disable-next-line:no-null-keyword
                    return null;
                }
            }
        })
        .build();

    return http.signalRConnection;
}) as any;

export const getSignalRHubConnection = (): HubConnection => ((dispatch, getState, http: Http) => {
    return http.signalRConnection;
}) as any;

export const clearSettingsSearchWindow = () => (dispatch, getState, http: Http) => {
    const { messaging } = getState();

    if (messaging.showSettingsMenu) {
        dispatch(showSettingsMenuFunc());
    } else if (messaging.showSearchMenu) {
        dispatch(showSearchMenuFunc());
    }
};

export const searchConversations = (triggerType: TriggerType, searchQuery?: string, page?: number, size?: number, withoutMessages?: boolean) => action<Promise<ISearchResponse<ISingleConversation>>>((dispatch, getState, http) => {
    const { messaging } = getState();
    const queryParams = queryString.stringify({
        searchQuery: searchQuery || '*',
        page: page || 0,
        size: size || messaging.itemsOnPageCount
    });
    dispatch({ type: actionTypes.PENDING_SEARCH_CONVERSATIONS });
    return http.messagingApiGet(`/Conversations/search?${queryParams}`)
        .then((conversations: ISearchResponse<ISingleConversation>) => {
            dispatch({
                type: actionTypes.SEARCH_CONVERSATIONS,
                conversations,
                spread: triggerType === TriggerType.Scroller
            });
            if (conversations?.items?.length && !messaging.currentConversation && !withoutMessages) {
                /* dispatch(getMessages(50, conversations.items[0].id)); */
                dispatch(markAsRead(conversations.items[0].lastMessage?.messageId || '0'));
            }
            return conversations;
        });
});

export const setCurrentConversation = (currentConversation: ISingleConversation) => ({
    type: actionTypes.SET_CURRENT_CONVERSATION,
    currentConversation,
    currentConversationId: currentConversation.id
});

export const clearConversations = () => ({
    type: actionTypes.CLEAR_CONVERSATIONS
});

export const setConversationMenu = (value: boolean, expanded?: boolean) => ({
    type: actionTypes.SET_CONVERSATION_MENU,
    value,
    expanded
});

export const setConversationMenuExpanded = (expanded: boolean) => ({
    type: actionTypes.SET_CONVERSATION_MENU_EXPANDED,
    expanded
});

export const setActiveConversationVisibility = (conversationId: string, value: boolean) => ({
    type: actionTypes.SET_ACTIVE_CONVERSATION_VISIBILITY,
    conversationId,
    value
});

export const removeActiveConversation = (conversationId: string) => ({
    type: actionTypes.REMOVE_ACTIVE_CONVERSATION,
    conversationId
});

export const addActiveConversation = (conversation: ISingleConversation) => ((dispatch, getState: () => IState, http: Http) => {
    dispatch({
        type: actionTypes.ADD_ACTIVE_CONVERSATION,
        conversationId: conversation?.id,
        conversation
    });
});