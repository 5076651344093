import React, { FC, useCallback, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faDoNotEnter, faExclamationTriangle, faInfoSquare } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { modalsShadow } from '../../styleHelpers/mixins/shadow';
import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import { media } from '../../styleHelpers/breakpoint';
import { AlertType } from '../../entities/IAlert';
import { hideAndRemoveAlert } from '../../actions/alertActions';

const progressAnimation = keyframes`
    from {
        width: 100%;
    }

    to {
        width: 0;
    }
`;

const Progress = styled.div`
    height: 5px;
    animation: ${progressAnimation} 10s linear;
    animation-fill-mode: forwards;
`;

interface IAlertWrapperProps {
    alertType: AlertType;
    isVisible: boolean;
    isPaused: boolean;
}

const Wrapper = styled.div<IAlertWrapperProps>`
    background: #fff;
    margin-top: 1rem;
    width: 100%;
    border-radius: 4px;
    font-size: ${fontSize[13]};
    color: ${colorStack.darkBlue};
    transform: translateX(200%);
    transition: transform 600ms;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    ${modalsShadow()};
    ${media.tabletSm`
        width: 300px;
        margin: 1rem 1rem 0 1rem;
    `}
    ${props => props.isVisible && css`
        transform: translateX(0);
    `}
    ${props => props.alertType === AlertType.Success && css`
        background: ${colorStack.whiteGreen};
        ${Progress} {
            background: ${colorStack.middleGreen};
        }
    `}
    ${props => props.alertType === AlertType.Warning && css`
        background: ${colorStack.whiteOrange};
        ${Progress} {
            background: ${colorStack.darkOrange};
        }
    `}
    ${props => props.alertType === AlertType.Error && css`
        background: ${colorStack.lightRed};
        ${Progress} {
            background: ${colorStack.middleRed};
        }
    `}
    ${props => props.alertType === AlertType.Info && css`
        background: ${colorStack.whiteBlue};
        ${Progress} {
            background: ${colorStack.darkBlue};
        }
    `}
    &:hover {
        ${Progress} {
            animation-play-state: paused;
        }
    }
    ${props => props.isPaused && css`
        ${Progress} {
            animation-play-state: paused;
        }
    `}
`;

const Content = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;

    svg {
        flex-shrink: 0;
        margin-right: 1rem;
    }
`;

const ProgressWrapper = styled.div`
    height: 5px;
`;

const CloseBtn = styled.button`
    position: absolute;
    cursor: pointer;
    outline: 0;
    top: 5px;
    right: 5px;
`;

interface IAlertProps {
    type: AlertType;
    visible: boolean;
    alertId: string | number;
}

export const Alert: FC<IAlertProps> = ({ children, type, visible, alertId }) => {
    const dispatch = useDispatch();
    const [paused, setPaused] = useState<boolean>(false);

    const hideAlert = useCallback(() => {
        dispatch(hideAndRemoveAlert(alertId));
    }, [alertId]);

    const onClick = useCallback(() => {
        setPaused(state => !state);
    }, []);

    return (
        <Wrapper alertType={type} isVisible={visible} isPaused={paused} onClick={onClick}>
            <CloseBtn onClick={hideAlert}>
                <FontAwesomeIcon icon={faTimes} />
            </CloseBtn>
            <Content>
                {type === AlertType.Success && (
                    <FontAwesomeIcon icon={faCheckSquare} color={colorStack.middleGreen} size="lg" />
                )}
                {type === AlertType.Warning && (
                    <FontAwesomeIcon icon={faExclamationTriangle} color={colorStack.darkOrange} size="lg" />
                )}
                {type === AlertType.Error && (
                    <FontAwesomeIcon icon={faDoNotEnter} color={colorStack.middleRed} size="lg" />
                )}
                {type === AlertType.Info && (
                    <FontAwesomeIcon icon={faInfoSquare} color={colorStack.darkBlue} size="lg" />
                )}
                <div>
                    {children}
                </div>
            </Content>
            <ProgressWrapper>
                <Progress onAnimationEnd={hideAlert} />
            </ProgressWrapper>
        </Wrapper>
    );
};
