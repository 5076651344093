import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { getFileExt } from '../../../tools/clusterFilesystemTools';
import { downloadDocuments } from '../../../actions/clustersActions';
import { isImage, isPdf } from '../../../tools/generalTools';
import { convertToBinary, textTruncate } from '../../../tools/string';
import { useUrlQuery, useDropdown, useAlert } from '../../../tools/hooks';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import * as fsActions from '../../../actions/clustersFilesystemActions';
import { FilesystemImageViewer } from '../FileViewers/FilesystemImageViewer';
import { FilesystemPdfViewer } from '../FileViewers/FilesystemPdfViewer';
import { AlertType } from '../../../entities/IAlert';
import { downloadDocument } from '../../../actions/legalEntitiesActions';
import { FolderIcon } from '../../Organization/ClustersV2/ClusterDocuments/FileManager/FolderIcon';

type DownloadDocuments = ReturnType<typeof downloadDocuments>;
type DownloadDocument = ReturnType<typeof downloadDocument>;

type ColorMode = 'text' | 'presentation' | 'image' | 'tables' | '';

const Wrapper = styled.div`
    border-radius: 4px;
    border: 1px solid ${colorStack.lightBlue};
    padding: 3px;
    margin: 0 0.5rem 0 0;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Roboto', sans-serif;
    position: relative;
    width: 240px;
`;

const Remove = styled.div`
    color: ${colorStack.darkBlue};
    position: absolute;
    right: 3% !important;
    top: 3%;
    cursor: pointer;
`;

const Extension = styled.div<{ colorMode: ColorMode; $quickView: boolean }>`
    font-size: ${fontSize[13]};
    font-weight: 500;
    color: ${colorStack.darkBlue};
    background: ${colorStack.whiteBlue};
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    justify-content: center;
    margin: 0 12px 0 0;
    ${props =>
        props.$quickView
            ? css`
                  cursor: pointer;
              `
            : css`
                  cursor: not-allowed;
              `}
    ${props =>
        props.color === 'text' &&
        css`
            background: ${colorStack.whiteBlue};
        `}
    ${props =>
        props.color === 'presentation' &&
        css`
            background: ${colorStack.whiteOrange};
        `}
    ${props =>
        props.color === 'tables' &&
        css`
            background: ${colorStack.whiteGreen};
        `}
    ${props =>
        props.color === 'image' &&
        css`
            background: ${colorStack.whiteRed};
        `}
`;

const Name = styled.div<{ $quickView: boolean }>`
    font-size: ${fontSize[13]};
    font-weight: 700;
    ${props =>
        props.$quickView
            ? css`
                  cursor: pointer;
              `
            : css`
                  cursor: not-allowed;
              `}
`;

const Content = styled.div<{}>`
    display: flex;
    width: 100%;
    align-items: center;
`;

const ButtonsMenu = styled.div`
    position: relative;
    margin: 0 0 0 auto;
    cursor: pointer;
    svg {
        color: ${colorStack.blue};
    }
`;

interface IProps {
    entityId?: string;
    documentId?: string;
    fileName: string;
    id?: string;
    hideCopyUrl?: boolean;
    hideDownload?: boolean;
    clusterId?: string;
    canRemove?: boolean;
    visibleButtons?: boolean;
    overviewPath?: boolean;
    customPath?: string;
    sourceName?: string;
    isFolder?: boolean;
    hideView?: boolean;
    removeHandler?(id: string);
}

export const Attachment = (props: IProps) => {
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const { organization } = useParams<{ organization: string }>();
    const [showImageViewer, setShowImageViewer] = useState<boolean>(false);
    const [showPdfViewer, setShowPdfViewer] = useState<boolean>(false);
    const [viewerName, setViewerName] = useState<string>(undefined);
    const [viewerDirectUrl, setViewerDirectUrl] = useState<string>(undefined);
    const query = useUrlQuery<{ path: string }>();
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    const openCloseImage = useCallback(() => {
        setShowImageViewer(currentState => !currentState);
    }, []);

    const openClosePdf = useCallback(() => {
        setShowPdfViewer(currentState => !currentState);
    }, []);

    const fileExtension = getFileExt(props?.fileName)?.ext;
    const overviewFiles = 'Overview Files';
    const path = props?.customPath ? props?.customPath : props.overviewPath ? overviewFiles : query?.path;

    const viewHandler = useCallback((e) => {
        e.stopPropagation();
        closeDropdown();
        if (props.clusterId) {
            dispatch<DownloadDocuments>(downloadDocuments(props.clusterId, props.fileName, path))
                .then(response => {
                    setViewerName(response.name);
                    setViewerDirectUrl(response.directUri);
                    isImage(fileExtension) ? openCloseImage() : isPdf(fileExtension) && openClosePdf();
                })
                .catch(() => {
                    addAlert(<FormattedMessage id="clusterLinks.download.error" />, AlertType.Error);
                });
        } else {
            dispatch<DownloadDocument>(downloadDocument(props.entityId, props.documentId))
                .then(response => {
                    setViewerName(response?.name);
                    setViewerDirectUrl(response?.directUri);
                    isImage(fileExtension) ? openCloseImage() : isPdf(fileExtension) && openClosePdf();
                })
                .catch(() => {
                    addAlert(<FormattedMessage id="clusterLinks.download.error" />, AlertType.Error);
                });
        }
    }, [openClosePdf, openCloseImage, path, props, fileExtension]);

    const downloadHandler = useCallback((e) => {
        e.stopPropagation();
        closeDropdown();
        if (props.clusterId) {
            dispatch(fsActions.getFileMetadata(props.clusterId, [path], props.fileName))
                .then(response => {
                    response?.directUri && (window.location.href = response?.directUri);
                })
                .catch(() => {
                    addAlert(<FormattedMessage id="clusterFilesystem.download.error" />, AlertType.Error);
                });
        } else {
            dispatch<DownloadDocument>(downloadDocument(props.entityId, props.documentId))
                .then(response => {
                    response?.directUri && (window.location.href = response?.directUri);
                })
                .catch(() => {
                    addAlert(<FormattedMessage id="clusterFilesystem.download.error" />, AlertType.Error);
                });
        }
    }, [props.fileName, props.clusterId, path]);

    const removeHandler = () => {
        props.removeHandler(props?.id);
    };

    const copyHandler = useCallback(() => {
        dispatch(fsActions.getFileMetadata(props.clusterId, [path], props.fileName))
            .then(() => {
                navigator.clipboard.writeText(
                    `${window.location.origin}/orgs/${organization}/cluster/${
                        props.clusterId
                    }/documents?path=${path}&getFilea=${convertToBinary(props.fileName)}&getFileb=${convertToBinary(
                        path
                    )}`
                );
                addAlert(<FormattedMessage id="cluster.alert.urlCopied" />, AlertType.Success);
            })
            .catch(() => {
                addAlert(<FormattedMessage id="clusterLinks.download.error" />, AlertType.Error);
            });
    }, [props.fileName, props.clusterId, path, organization]);

    const getColorMode = useCallback((ext: string): ColorMode => {
        const text = ['doc', 'docm', 'docx', 'wpd', 'txt', 'pdf', 'xps', 'rtf', 'msg'].includes(ext) && 'text';
        const presentation = ['pptx', 'ppt', 'pptm', 'pps', 'pot', 'potx'].includes(ext) && 'presentation';
        const tables = ['csv', 'xls', 'xlsm', 'xlsx'].includes(ext) && 'tables';
        const image = ['bmp', 'gif', 'jpg', 'jpeg', 'png', 'tif', 'tiff'].includes(ext) && 'image';
        return text || presentation || tables || image;
    }, []);

    const hasQuickView = useCallback(
        (ext: string) => {
            return !!(props.documentId || props.clusterId) && (isPdf(ext) || isImage(ext));
        },
        [props?.fileName]
    );

    const quickViewAllowed = hasQuickView(fileExtension);

    return (
        <Wrapper>
            <Content>
                <Extension
                    colorMode={getColorMode(fileExtension)}
                    $quickView={quickViewAllowed}
                    onClick={quickViewAllowed ? viewHandler : undefined}
                >
                    {props.isFolder ? <FolderIcon /> : <>{fileExtension}</>}
                </Extension>
                {props?.fileName && (
                    <Name $quickView={quickViewAllowed} onClick={quickViewAllowed ? viewHandler : undefined}>
                        {textTruncate(props?.sourceName || props?.fileName, 20)}
                    </Name>
                )}
                {props.visibleButtons && props?.fileName && !props.entityId && (
                    <ButtonsMenu ref={wrapperRef}>
                        <FontAwesomeIcon icon={faEllipsisV} onClick={toggleDropdown} />
                        {dropdownOpen && (
                            <ContextMenu
                                links={[
                                    {
                                        name: <FormattedMessage id="global.view" />,
                                        action: viewHandler,
                                        visible: !props.hideView && quickViewAllowed
                                    },
                                    {
                                        name: <FormattedMessage id="global.copyUrl" />,
                                        action: copyHandler,
                                        visible: !props?.hideCopyUrl
                                    },
                                    {
                                        name: <FormattedMessage id="global.download" />,
                                        action: downloadHandler,
                                        visible: !props?.hideDownload
                                    },
                                    {
                                        name: <FormattedMessage id="global.remove" />,
                                        action: removeHandler,
                                        visible: props?.removeHandler && props.canRemove
                                    }
                                ]}
                            />
                        )}
                    </ButtonsMenu>
                )}
            </Content>
            {showImageViewer && props.visibleButtons && (
                <FilesystemImageViewer
                    entityId={props?.entityId}
                    documentId={props?.documentId}
                    imageName={viewerName}
                    clusterId={props?.clusterId}
                    displayDirectUrl={viewerDirectUrl}
                    currentPath={[path]}
                    closeViewer={openCloseImage}
                />
            )}
            {showPdfViewer && (
                <FilesystemPdfViewer
                    pdfName={viewerName}
                    clusterId={props?.clusterId}
                    document={viewerDirectUrl}
                    currentPath={[path]}
                    closeViewer={openClosePdf}
                />
            )}
        </Wrapper>
    );
};
