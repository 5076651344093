import React, { FC } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { TextComponent } from '../Inputs/TextComponent';
import { colorStack } from '../../../styleHelpers/colors';
import { headersShadow } from '../../../styleHelpers/mixins/shadow';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { IState } from '../../../reducers';
import { IProfileReducer } from '../../../reducers/profileReducer';
import { languageList } from '../../../tools/languageTools';
import { Lcid } from '../../../entities/ILanguage';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';

const InnerWrapper = styled.div`
    position: relative;
`;

const Wrapper = styled.div`
    font-family: 'Roboto', sans-serif;
    width: 100%;
    .react-date-picker__calendar {
        &--closed {
            display: none;
        }
        &--open {
            display: block;
            margin: 3px 0 0 0;
        }
    }
    .custom-calendar-wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        > div {
            border: none;
            display: flex;
            justify-content: space-between;
            padding: 8px 12px 4px 12px;
            height: 42px;
            > div {
                opacity: 0;
                width: 100%;
                cursor: pointer;
                input, span {
                    cursor: pointer;
                }
            }
        }
        .react-date-picker {
            &__wrapper {
                button {
                    cursor: pointer;
                    &:nth-child(2) {
                        margin: 0 1rem 0 auto;
                    }
                }
            }
        }
    }
    .react-calendar {
        background: ${colorStack.white};
        padding: 30px 24px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        width: 328px;
        z-index: 999;
        position: relative;
        ${headersShadow()};
        &__navigation{
            display: flex;
            padding: 0 0.8rem;
            justify-content: space-between;
            margin: 0 0 1.5rem 0;
            button {
                color: ${colorStack.middleBlue};
                font-size: ${fontSize[25]};
                cursor: pointer;
            }
            &__prev2-button {
                margin: 0 2rem 0 0;
            }
            &__next2-button {
                margin: 0 0 0 2rem;
            }
            &__label {
                font-size: ${fontSize[13]}!important;
            }
        }
        &__tile {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            font-size: ${fontSize[13]};
            color: ${colorStack.content};
            &--active {
                background: ${colorStack.whiteBlue};
                font-weight: 600;
            }
            &--hasActive {
                background: ${colorStack.whiteBlue};
            }
            &--now {
                background: ${colorStack.whiteBlue};
            }
        }
        &__year-view {
            &__months {
                &__month {
                    font-size: ${fontSize[16]};
                }
            }
        }
        &__month-view {
            &__days {
                &__day {
                    cursor: pointer;
                    &--neighboringMonth {
                        color: ${colorStack.disabled};
                    }
                    &:hover {
                        background: ${colorStack.ligthGrey};
                    }
                }
            }
            &__weekdays {
                margin: 0 0 0.8rem 0;
                &__weekday {
                    font-weight: 600;
                    font-size: ${fontSize[13]};
                    text-align: center;
                    cursor: default;
                    abbr {
                        text-decoration: none;
                    }
                }
            }
        }
    }
`;

interface IProps {
    value: Date;
    disabled?: boolean;
    placeholder?: string;
    minDate?: Date;
    maxDate?: Date;
    label?: string | React.ReactNode;
    required?: boolean;
    error?: boolean;
    errorMsg?: string;
    onChange(date: Date);
}

export const DatePickerWrapper: FC<IProps> = props => {

    const {currentUserProfile} = useSelector<IState, IProfileReducer>(state => state.profile);

    return (
        <Wrapper>
            {props.label &&
                <LabelWithEllipsis>
                    {props.label}
                    {props.required &&
                        <MandatoryFieldStar />
                    }
                </LabelWithEllipsis>
            }
            <InnerWrapper>
                <TextComponent
                    value={props.value ? moment.utc(props.value).local().format('DD MMM YYYY') : ''}
                    placeholder={props.placeholder}
                    error={props.error}
                    errorMsg={props.errorMsg}
                    errorWithoutIco
                    outerShadow
                    disabled={props.disabled}
                />
                <DatePicker
                    disabled={props.disabled}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    onChange={props.onChange}
                    value={props.value}
                    data-lc="js-lc-calendarWrapper"
                    calendarClassName="custom-calendar"
                    className="custom-calendar-wrapper"
                    calendarIcon={<FontAwesomeIcon icon={faCalendar} />}
                    clearIcon={<FontAwesomeIcon icon={faXmark} />}
                    locale={languageList[currentUserProfile?.preferredLanguage] || languageList[Lcid.Fr]}
                />
            </InnerWrapper>
        </Wrapper>
    );
};