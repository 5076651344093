import { RefObject } from 'react';

import { colorStack } from '../styleHelpers/colors';
import { IValue } from './IPickers';

export interface IReferencial {
    Key: string;
    id: string;
    logicalId: number;
    name: string;
    parents?: string[];
    referentialIdentityId?: string;
}

export interface IViewReferential {
    key: string;
    parentId: string;
    uniqueKey: string;
}

export interface IUserBrief {
    firstName: string;
    lastName: string;
    jobTitle: string;
    picture: string;
    cabinet: string;
    id: string;
    emailContact?: string;
    type?: 'Personal';
}

export enum IProfileType {
    Personal = 'personal',
    Group = 'group',
    Company = 'company',
    Cluster = 'cluster'
}

export interface ITeamReferential {
    DepartmentReferentialId: string;
    id: string;
    Name: string;
}

export enum Referentials {
    Nationality = 'Nationality',
    Theme = 'Theme',
    Currency = 'Currency',
    Group = 'Group',
    Position = 'Position',
    SecondaryEstablishmentsAndBranches = 'SecondaryEstablishmentsAndBranches',
    SubsidiaryAndParticipations = 'SubsidiaryAndParticipations',
    TypeOfLegalEntity = 'TypeOfLegalEntity',
    LegalForm = 'LegalForm',
    FirstReferential = 'FirstReferential',
    SecondReferential = 'SecondReferential',
    ThirdReferential = 'ThirdReferential',
    Information = 'Information',
    Administration = 'Administration',
    Thematic = 'Thematic',
    Country = 'Country',
    QualityBranches = 'QualityBranches',
    QualityParticipations = 'QualityParticipations',
    NumberOfEmployees = 'NumberOfEmployees',
    DocumentLabel = 'DocumentLabel',
    Clauses = 'Clauses',
    TypeOfActivity = 'TypeOfActivity',
    AliasType = 'AliasType',
    GiftNature = 'GiftNature',
    GiftRelationshipNature = 'GiftRelationshipNature',
    GiftValue = 'GiftValue',
    GiftDescription = 'GiftDescription',
    TypeOfMilestone = 'TypeOfMilestone',
    ContractTypeReferential = 'ContractTypeReferential',
    Assignment = 'Assignment',
    NonCompeteProvision = 'NonCompeteProvision',
    ChangeOfControl = 'ChangeOfControl',
    GeographicalArea = 'GeographicalArea',
    PerformanceObligation = 'PerformanceObligation',
    TypeOfRenewal = 'TypeOfRenewal',
    BasicPrice = 'BasicPrice',
    Exclusivity = 'Exclusivity',
    ApplicableLaw = 'ApplicableLaw',
    ProductAndServices = 'ProductAndServices',
    Cancellation = 'Cancellation',
    ContractDuration = 'ContractDuration',
    GenericQualifiaction = 'GenericQualifiaction',
    ContractTermType = 'ContractTermType',
    BusinessSector = 'BusinessSector',
    Liability = 'Liability',
    Department = 'Department',
    KeyDateStatus = 'KeyDateStatus',
    KeyDateTitle = 'KeyDateTitle',
    InvitationNature = 'InvitationNature',
    InvitationType = 'InvitationType',
    NatureOfRelationship = 'NatureOfRelationship',
    ApprovalLabel = 'ApprovalLabel',
    CoiConflictStatusType = 'CoiConflictStatusType',
    CoiManagerDecision = 'CoiManagerDecision',
    CoiLinkType = 'CoiLinkType',
    ListOfLobbyingRecords = 'ListOfLobbyingRecords',
    LegalSupportSubject = 'LegalSupportSubject',
    Percentage = 'Percentage'
}

export enum IContextList {
    Data = 'Data',
    Stake = 'Stake',
    Profile = 'Profile',
    Client = 'Client',
    NormativeDocumentation = 'NormativeDocumentation',
    Quotation = 'Quotation',
    Platform = 'Platform',
    LegalEntity = 'LegalEntity',
    BasicCluster = 'BasicCluster',
    Contract = 'Contract',
    Corporate = 'Corporate',
    POA = 'POA ',
    GiftInvitation = 'GiftInvitation',
    Sponsoring = 'Sponsoring',
    COI = 'COI',
    LegalSupport = 'LegalSupport',
    Lobbing = 'Lobbing',
    AssetManagement = 'AssetManagement'
}

export interface Icon {
    width?: number | string;
    height?: number | string;
    [key: string]: any;
    className?: string;
    selected?: boolean;
    innerRef?: RefObject<SVGSVGElement>;
}

export enum IPopupSizes {
    Small,
    Medium,
    Big,
    Monster,
    Full
}

export interface IAddressGlobal {
    number: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    countryShort: string;
    CountryShort?: string;
}

export interface ISingleAttachment {
    name: string;
    uri: string;
    fileType: string;
    size: string;
}

export interface ISearchResponse<T> {
    items: T[];
    size: number;
    page: number;
    count: number;
    itemsOnPageCount: number;
}

export enum EClusterStatus {
    Active = 'Active',
    Closed = 'Closed',
    Archived = 'Archived'
}

export enum ESignatureStatus {
    Expired = 'Expired',
    InForce = 'InForce',
    WaitingForSignature = 'WaitingForSignature',
    WaitingForApostil = 'WaitingForApostil',
    DraftOnGoing = 'DraftOnGoing',
    ToDo = 'ToDo'
}

export enum EAssesmentStatus {
    AssessmentRequired = 'AssessmentRequired',
    PositiveOpinionWithoutRestriction = 'PositiveOpinionWithoutRestriction',
    PositiveOpinionWithRestrictions = 'PositiveOpinionWithRestrictions',
    NegativeOpinion = 'NegativeOpinion',
    'SponsoringAssessmentStatus.assessmentrequired' = 'SponsoringAssessmentStatus.assessmentrequired',
    'SponsoringAssessmentStatus.positiveopinionwithoutrestriction' = 'SponsoringAssessmentStatus.positiveopinionwithoutrestriction',
    'SponsoringAssessmentStatus.positiveopinionwithrestrictions' = 'SponsoringAssessmentStatus.positiveopinionwithrestrictions',
    'SponsoringAssessmentStatus.negativeopinion' = 'SponsoringAssessmentStatus.negativeopinion'
}

export enum ECoiStatus {
    EvaluationRequired = 'EvaluationRequired',
    NoConflictOfInterest = 'NoConflictOfInterest',
    PotentialConflictOfInterest = 'PotentialConflictOfInterest',
    ApparentConflictOfInterest = 'ApparentConflictOfInterest',
    'coiEnums.conflict-status.evaluationrequired' = 'coiEnums.conflict-status.evaluationrequired',
    'coiEnums.conflict-status.noconflictofinterest' = 'coiEnums.conflict-status.noconflictofinterest',
    'coiEnums.conflict-status.potentialconflictofinterest' = 'coiEnums.conflict-status.potentialconflictofinterest',
    'coiEnums.conflict-status.apparentconflictofinterest' = 'coiEnums.conflict-status.apparentconflictofinterest'
}

export enum ECorporateMandateStatus {
    NoStatus = 'NoStatus',
    Active = 'Active',
    EmminentEnd = 'EmminentEnd',
    Expired = 'Expired',
    Inactive = 'Inactive'
}

export enum ECampaignStatus {
    'campaign.status.closed' = 'campaign.status.closed',
    'campaign.status.active' = 'campaign.status.active',
    'campaign.status.draft' = 'campaign.status.draft',
    'campaign.status.completed' = 'campaign.status.completed'
}

export enum ECampaignRecipientStatus {
    'campaign.recipient.status.pending' = 'campaign.recipient.status.pending',
    'campaign.recipient.status.emailSent' = 'campaign.recipient.status.emailSent',
    'campaign.recipient.status.acceptedInSendgrid' = 'campaign.recipient.status.acceptedInSendgrid',
    'campaign.recipient.status.emailDelivered' = 'campaign.recipient.status.emailDelivered',
    'campaign.recipient.status.emailOpened' = 'campaign.recipient.status.emailOpened',
    'campaign.recipient.status.urlOpened' = 'campaign.recipient.status.urlOpened',
    'campaign.recipient.status.completed' = 'campaign.recipient.status.completed',
    'campaign.recipient.status.errorDelivery' = 'campaign.recipient.status.errorDelivery',
    'campaign.recipient.status.emailGenerationError' = 'campaign.recipient.status.emailGenerationError',
    'campaign.recipient.status.recipientHasOtherActiveCampaign' = 'campaign.recipient.status.recipientHasOtherActiveCampaign',
    'campaign.recipient.status.readyToSend' = 'campaign.recipient.status.readyToSend'
}

export enum EAcceptanceStatus {
    NoResponse = 'NoResponse',
    Draft = 'Draft',
    InternalReview = 'InternalReview',
    AwaitingApproval = 'AwaitingApproval',
    Approved = 'Approved',
    Closed = 'Closed',
    DealBreaker = 'DealBreaker'
}

export enum EValidatorStatus {
    WaitingForPreviousStep = 'WaitingForPreviousStep',
    InProgress = 'InProgress',
    WaitingForAction = 'WaitingForAction',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Favorable = 'Favorable',
    Unfavorable = 'Unfavorable',
    Acknowledged = 'Acknowledged'
}

export enum EValidationRequestStatus {
    Done = 'Done',
    Disabled = 'Disabled',
    Rejected = 'Rejected',
    Completed = 'Completed',
    Active = 'Active'
}

export enum EValidationRequestStageStatus {
    WaitingForPreviousStep = 'WaitingForPreviousStep',
    InProgress = 'InProgress',
    Done = 'Done',
    Approved = 'Approved',
    Rejected = 'Rejected'
}

export enum EStatus {
    Delivered = 'Delivered',
    sent = 'sent',
    delivered = 'delivered',
    completed = 'completed',
    declined = 'declined',
    NotStarted = 'NotStarted',
    Overdue = 'Overdue',
    Hidden = 'Hidden',
    InProgressWithBlank = 'in progress',
    Pending = 'Pending',
    EmailSent = 'EmailSent',
    AcceptedInSendgrid = 'AcceptedInSendgrid',
    ErrorDelivery = 'ErrorDelivery',
    EmailDelivered = 'EmailDelivered',
    EmailOpened = 'EmailOpened',
    UrlOpened = 'UrlOpened',
    NoConflict = 'NoConflict',
    NoDeclaration = 'NoDeclaration',
    HasOtherActiveCampaign = 'HasOtherActiveCampaign',
    AtLeastOneDeclarationRequireAssessment = 'AtLeastOneDeclarationRequireAssessment',
    OneToManyPotentialConflictOfInterest = 'OneToManyPotentialConflictOfInterest',
    OneToManyConflictOfInterest = 'OneToManyConflictOfInterest'
}

export type AllStatusEnums = EStatus | EClusterStatus | ESignatureStatus | EAssesmentStatus | ECoiStatus | ECorporateMandateStatus | ECampaignStatus | ECampaignRecipientStatus | EAcceptanceStatus | EValidatorStatus | EValidationRequestStatus | EValidationRequestStageStatus;

export const StatusMapper: IValue[] = [
    { key: EAcceptanceStatus.NoResponse, text: `status.${EAcceptanceStatus.NoResponse}`, data: { color: colorStack.red, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.Draft, text: `status.${EAcceptanceStatus.Draft}`, data: { color: colorStack.blue, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.InternalReview, text: `status.${EAcceptanceStatus.InternalReview}`, data: { color: colorStack.purple, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.AwaitingApproval, text: `status.${EAcceptanceStatus.AwaitingApproval}`, data: { color: colorStack.orange, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.Approved, text: `status.${EAcceptanceStatus.Approved}`, data: { color: colorStack.green, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.DealBreaker, text: `status.${EAcceptanceStatus.DealBreaker}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EAcceptanceStatus.Closed, text: `status.${EAcceptanceStatus.Closed}`, data: { color: colorStack.middleGrey, fontColor: colorStack.darkGrey } },
    { key: EValidationRequestStatus.Done, text: `status.${EValidationRequestStatus.Done}`, data: { color: colorStack.green, fontColor: colorStack.darkGrey } },
    { key: EValidatorStatus.Acknowledged, text: `status.${EValidatorStatus.Acknowledged}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EValidatorStatus.Unfavorable, text: `status.${EValidatorStatus.Unfavorable}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EValidatorStatus.Favorable, text: `status.${EValidatorStatus.Favorable}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EValidationRequestStatus.Disabled, text: `status.${EValidationRequestStatus.Disabled}`, data: { color: colorStack.middleGrey, fontColor: colorStack.white } },
    { key: EValidationRequestStatus.Completed, text: `status.${EValidationRequestStatus.Completed}`, data: { color: colorStack.green, fontColor: colorStack.white } },
    { key: EValidationRequestStatus.Active, text: `status.${EValidationRequestStatus.Active}`, data: { color: colorStack.orange, fontColor: colorStack.darkGrey } },
    { key: EValidationRequestStatus.Rejected, text: `status.${EValidationRequestStatus.Rejected}`, data: { color: colorStack.red, fontColor: colorStack.darkGrey } },
    { key: EStatus.Delivered, text: `status.${EStatus.Delivered}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EValidationRequestStageStatus.InProgress, text: `status.${EValidationRequestStageStatus.InProgress}`, data: { color: colorStack.orange, fontColor: colorStack.darkGrey } },
    { key: EValidationRequestStageStatus.WaitingForPreviousStep, text: `status.${EValidationRequestStageStatus.WaitingForPreviousStep}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EStatus.sent, text: `status.${EStatus.sent}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.delivered, text: `status.${EStatus.delivered}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EValidatorStatus.WaitingForAction, text: `status.${EValidatorStatus.WaitingForAction}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.Hidden, text: `status.${EStatus.Hidden}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.completed, text: `status.${EStatus.completed}`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: EStatus.declined, text: `status.${EStatus.declined}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EStatus.Overdue, text: `status.${EStatus.Overdue}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ESignatureStatus.DraftOnGoing, text: `status.${ESignatureStatus.DraftOnGoing}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: ESignatureStatus.WaitingForApostil, text: `status.${ESignatureStatus.WaitingForApostil}`, data: { color: colorStack.purple, fontColor: colorStack.whitePurple } },
    { key: ESignatureStatus.WaitingForSignature, text: `status.${ESignatureStatus.WaitingForSignature}`, data: { color: colorStack.darkOrange, fontColor: colorStack.whiteOrange } },
    { key: ESignatureStatus.InForce, text: `status.${ESignatureStatus.InForce}`, data: { color: colorStack.green, fontColor: colorStack.whiteGreen } },
    { key: ESignatureStatus.ToDo, text: `status.${ESignatureStatus.ToDo}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ESignatureStatus.Expired, text: `status.${ESignatureStatus.Expired}`, data: { color: colorStack.disabled, fontColor: colorStack.ligthGrey } },
    { key: ECoiStatus.NoConflictOfInterest, text: `status.${ECoiStatus.NoConflictOfInterest}`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECoiStatus.PotentialConflictOfInterest, text: `status.${ECoiStatus.PotentialConflictOfInterest}`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: ECoiStatus.ApparentConflictOfInterest, text: `status.${ECoiStatus.ApparentConflictOfInterest}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EStatus.InProgressWithBlank, text: `status.${EStatus.InProgressWithBlank}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.ErrorDelivery, text: `status.${EStatus.ErrorDelivery}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EStatus.Pending, text: `status.${EStatus.Pending}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.EmailSent, text: `status.${EStatus.EmailSent}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.EmailDelivered, text: `status.${EStatus.EmailDelivered}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.EmailOpened, text: `status.${EStatus.EmailOpened}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.HasOtherActiveCampaign, text: `status.${EStatus.HasOtherActiveCampaign}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.UrlOpened, text: `status.${EStatus.UrlOpened}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.NoDeclaration, text: `status.${EStatus.NoDeclaration}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.NoConflict, text: `status.${EStatus.NoConflict}`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: EStatus.AtLeastOneDeclarationRequireAssessment, text: `status.${EStatus.AtLeastOneDeclarationRequireAssessment}`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: EStatus.OneToManyPotentialConflictOfInterest, text: `status.${EStatus.OneToManyPotentialConflictOfInterest}`, data: { color: colorStack.darkOrange, fontColor: colorStack.whiteOrange } },
    { key: EStatus.OneToManyConflictOfInterest, text: `status.${EStatus.OneToManyConflictOfInterest}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EAssesmentStatus.AssessmentRequired, text: `status.${EAssesmentStatus.AssessmentRequired}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EAssesmentStatus.PositiveOpinionWithoutRestriction, text: `status.${EAssesmentStatus.PositiveOpinionWithoutRestriction}`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: EAssesmentStatus.PositiveOpinionWithRestrictions, text: `status.${EAssesmentStatus.PositiveOpinionWithRestrictions}`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: EAssesmentStatus.NegativeOpinion, text: `status.${EAssesmentStatus.NegativeOpinion}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EAssesmentStatus['SponsoringAssessmentStatus.assessmentrequired'], text: `SponsoringAssessmentStatus.assessmentrequired`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EAssesmentStatus['SponsoringAssessmentStatus.positiveopinionwithoutrestriction'], text: `SponsoringAssessmentStatus.positiveopinionwithoutrestriction`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: EAssesmentStatus['SponsoringAssessmentStatus.positiveopinionwithrestrictions'], text: `SponsoringAssessmentStatus.positiveopinionwithrestrictions`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: EAssesmentStatus['SponsoringAssessmentStatus.negativeopinion'], text: `SponsoringAssessmentStatus.negativeopinion`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.pending'], text: `campaign.recipient.status.pending`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.emailSent'], text: `campaign.recipient.status.emailSent`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.acceptedInSendgrid'], text: `campaign.recipient.status.acceptedInSendgrid`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.emailDelivered'], text: `campaign.recipient.status.emailDelivered`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.emailOpened'], text: `campaign.recipient.status.emailOpened`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.urlOpened'], text: `campaign.recipient.status.urlOpened`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.completed'], text: `campaign.recipient.status.completed`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.errorDelivery'], text: `campaign.recipient.status.errorDelivery`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.emailGenerationError'], text: `campaign.recipient.status.emailGenerationError`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.recipientHasOtherActiveCampaign'], text: `campaign.recipient.status.recipientHasOtherActiveCampaign`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.readyToSend'], text: `campaign.recipient.status.readyToSend`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignStatus['campaign.status.closed'], text: `campaign.status.closed`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECampaignStatus['campaign.status.active'], text: `campaign.status.active`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: ECampaignStatus['campaign.status.draft'], text: `campaign.status.draft`, data: { color: colorStack.disabled, fontColor: colorStack.ligthGrey } },
    { key: ECampaignStatus['campaign.status.completed'], text: `campaign.status.completed`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECoiStatus['coiEnums.conflict-status.evaluationrequired'], text: `coiEnums.conflict-status.evaluationrequired`, data: { color: colorStack.black, fontColor: colorStack.white } },
    { key: ECoiStatus['coiEnums.conflict-status.noconflictofinterest'], text: `coiEnums.conflict-status.noconflictofinterest`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECoiStatus['coiEnums.conflict-status.potentialconflictofinterest'], text: `coiEnums.conflict-status.potentialconflictofinterest`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: ECoiStatus['coiEnums.conflict-status.apparentconflictofinterest'], text: `coiEnums.conflict-status.apparentconflictofinterest`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } }
];