import { ISingleInvolvedPartie } from './IClusters';
import { IReferencial, IUserBrief } from './IGlobal';

export type IHyperlinkDataType = {
    [key: string]:
    | {
        routeName: 'Campaign';
        text: string;
        hyperlinkParameters: {
            campaignId: string;
        }
    }
    | {
        routeName: 'Cluster';
        text: string;
        hyperlinkParameters: {
            clusterId: string;
        }
    }
    | {
        routeName: 'DraftCampaign';
        text: string;
        hyperlinkParameters: {
            campaignId: string;
        }
    }
};

export interface IClusterLinkDataType {
    [key: string]: { name: string, id: string }
}

export interface ISignatureLinkDataType {
    [key: string]: { title: string, clusterId: string }
}

export interface IApprovalLinkDataType {
    [key: string]: { clusterId: string, validationId: string, name: string }
}

export interface IEnumDataType {
    [key: string]: { enumElement: string, translationKey: string, value: any }
}

export interface IPartyDataType {
    [key: string]: ISingleInvolvedPartie[]
}

export interface IPersonDataType {
    [key: string]: IUserBrief
}

export interface IDateDataType {
    [key: string]: string
}

export interface IDateColoredDataType {
    [key: string]: string
}

export interface IStatusDataType {
    [key: string]: string
}

export interface IExclamationMarkDataType {
    [key: string]: 'True' | 'No'
}

export interface IBooleanDataType {
    [key: string]: boolean
}

export interface INumberDataType {
    [key: string]: { number: string, unit: IReferencial, translatedUnit: string }
}

export enum ColumnType {
    string = 'string',
    datetime = 'datetime',
    number = 'number',
    boolean = 'boolean',
    date = 'date',
    dateColored = 'dateColored',
    text = 'text',
    party = 'party',
    clusterLink = 'clusterLink',
    person = 'person',
    legalEntity = 'legalEntity',
    referentials = 'referentials',
    mail = 'mail',
    status = 'status',
    exclamationMark = 'exclamationMark',
    richText = 'richText',
    approvalLink = 'approvalLink',
    signatureLink = 'signatureLink',
    enum = 'enum',
    numberWithConditionalColor = 'numberWithConditionalColor',
    hyperlink = 'hyperlink',
    booleanColored = 'booleanColored',
    comment = 'comment',
    pill = 'pill'
}

export enum EColoredDatagridField {
    Status = 'status'
}