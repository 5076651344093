import React, { useCallback, useState, useEffect, useRef, FC } from 'react';
import styled, { css } from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

import { TextComponent } from '../Inputs/TextComponent';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';
import { Avatar } from '../Avatar/Avatar';
import { cardsShadow, inputsShadow } from '../../../styleHelpers/mixins/shadow';
import { useDropdown } from '../../../tools/hooks';
import { NoData } from '../NoData/NoData';
import { Spinner } from '../Spinner/Spinner';
import { NewCompanyForm } from './Components/NewCompanyForm';
import { NewUserForm } from './Components/NewUserForm';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';
import { IProfileType } from '../../../entities/IGlobal';
import { IValue } from '../../../entities/IPickers';
import { Pill } from '../Pill/Pill';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border-radius: 4px;
`;

const NoDataWrapper = styled.div`
    padding: 1rem 0;
`;

const SearchResult = styled.div<{ withLabel: boolean }>`
    width: 100%;
    position: absolute;
    left: 0;
    background: ${colorStack.white};
    z-index: 99;
    button {
        display: flex;
        font-size: ${fontSize[13]};
        color: ${colorStack.label};
        padding: .5rem 1rem;
        width: 100%;
        margin: 0!important;
        text-align: left;
        cursor: pointer;
        align-items: center;
        &:hover {
            background: ${colorStack.ligthGrey};
        }
        > span {
            margin-left: 10px;
        }
    }
`;

const List = styled.div`
    width: 100%;
    ${cardsShadow()};
`;

const Listbox = styled.div<{ height: number }>`
    min-height: 48px;
    max-height: 175px;
    ${props => props.height && css`
        height: ${`${props.height}px`};
    `}
`;

const AddingButton = styled.div`
    color: ${colorStack.middleBlue};
    border-top: 1px solid ${colorStack.ligthGrey};
    font-weight: 500;
    height: 48px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;
    cursor:pointer;
    svg {
        margin: 0 0.5rem 0 0;
    }
`;

const TextWrapper = styled.div<{ selected: boolean; multiSelect: boolean; height: number }>`
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
    color: ${colorStack.content};
    min-height: 35px;
    > div {
        &:first-child {
            display: flex;
            align-items: flex-end;
        }
    }
    ${props => (props.multiSelect && props.height) && css`
        ${inputsShadow()};
        height: ${`${props.height}px`};
        .text-component {
            bottom: 0;
            width: 98%;
            position: absolute;
            left: 1%;
            input {
                box-shadow: none;
            }
        }
    `}
`;

const SelectedElemsList = styled.div<{ withLabel: boolean }>`
    display: flex;
    align-items: flex-start;
    position: absolute;
    flex-wrap: wrap;
    top: 6px;
    left: 12px;
    width: 93%;
    max-height: 114px;
    ${props => props.withLabel && css`
        top: 22px;
    `}
`;

const DoubleCreationWrapper = styled(AddingButton as any)`
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
    &:hover {
        background: unset;
    }
`;

const SingleCreateWrapper = styled.div`
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 9;
    cursor: pointer;
`;

const Loader = styled.div`
    display: flex;
    padding: .5rem 0;
    justify-content: center;
`;

export enum ButtonsTypes {
    Person = 'Person',
    Company = 'Company',
    All = 'All'
}

export interface IPickerProps {
    value?: IValue[];
    placeholder?: string;
    error?: boolean;
    errorMsg?: string | React.ReactNode;
    disabled?: boolean;
    multiSelect?: boolean;
    hideButtons?: boolean;
    label?: React.ReactNode;
    required?: boolean;
    data?: IValue[];
    buttonsType?: ButtonsTypes;
    disableOnClick?: boolean;
    withoutAvatar?: boolean;
    filter?: string[];
    onSelectElement?(value: IValue[]);
    onSelectElementMulti?(value: IValue[]);
    getSaveData?(data: any);
    onBlur?(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>);
    getData?(query?: string): Promise<IValue[]>;
}

let resultsDebounce: ReturnType<typeof setTimeout>;

export const CorePicker: FC<IPickerProps> = props => {
    const formikContext = useFormikContext();
    const selectedElemetsListRef = useRef<HTMLDivElement>();
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown, openDropdown] = useDropdown();
    const [searchResults, setSearchResults] = useState<IValue[]>([]);
    const [showCompanyForm, setshowCompanyForm] = useState<boolean>(false);
    const [showUserForm, setshowUserForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(undefined);
    const [selectedElements, setSelectedElements] = useState<IValue[]>(undefined);
    const [blurError, setBlurError] = useState<boolean>(false);

    useEffect(() => {
        props.value?.filter(elem => elem)?.length > 0 ? setSelectedElements(props.value.filter(elem => elem)) : setSelectedElements(undefined);
    }, [props.value]);

    useEffect(() => {
        if (!dropdownOpen && !props.value) {
            setInputValue(undefined);
        }
    }, [dropdownOpen]);

    useEffect(() => {
        if (selectedElements) {
            setBlurError(false);
        }
    }, [selectedElements]);

    const selectElement = useCallback((e: React.MouseEvent<HTMLButtonElement>, elem: IValue | string, isRemove?: boolean) => {
        e && closeDropdown();
        if (!isRemove) {
            props.multiSelect ? props.onSelectElementMulti(selectedElements ? [...selectedElements, elem as IValue] : [elem as IValue]) : props.onSelectElement([elem as IValue]);
        } else {
            props.multiSelect ? props.onSelectElementMulti(selectedElements.length === 1 ? [] : selectedElements.filter(element => element.key !== elem as string)) : props.onSelectElement([]);
        }
        setInputValue('');
        setSearchResults([]);
    }, [inputValue, setInputValue, setSearchResults, props.onSelectElement, formikContext?.values, selectedElements, selectedElemetsListRef, props.multiSelect]);

    const getData = useCallback((text: string) => {
        if (!props.disableOnClick || text.length > 0) {
            setSearchResults([]);
            resultsDebounce = setTimeout(async () => {
                const results = await props.getData(text);
                const filteredResults = results.filter(itemFromApi => !selectedElements?.find(selectedElement => selectedElement.key === itemFromApi.key));
                setLoading(false);
                setSearchResults(filteredResults);
            }, 500);
        }
    }, [selectElement, selectedElements, setSearchResults, props.getData, formikContext?.values, props.multiSelect]);

    const changeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        if (text.length > 0) {
            setLoading(true);
            openDropdown();
        } else {
            closeDropdown();
        }
        if (props.multiSelect || selectedElements?.length === 0 || !selectedElements) {
            setInputValue(text);
            clearTimeout(resultsDebounce);
            getData(text);
        }
    }, [setInputValue, props.getData, inputValue, formikContext?.values, loading, props.multiSelect, selectedElements, openDropdown]);

    const companyFormHandler = useCallback(() => {
        setshowCompanyForm(state => !state);
        toggleDropdown();
    }, [showCompanyForm]);
    const userFormHandler = useCallback(() => {
        setshowUserForm(state => !state);
        toggleDropdown();
    }, [showUserForm]);

    const removeElement = useCallback((value: IValue | string) => {
        if (!props.disabled) {
            selectElement(undefined, value, true);
            setInputValue(undefined);
        }
    }, [selectElement, props.disabled, selectedElements]);

    const onClick = useCallback(() => {
        if (!inputValue) {
            openDropdown();
            setLoading(true);
            getData('');
        }
    }, [inputValue, searchResults]);

    return (
        <Wrapper ref={wrapperRef}>
            <TextWrapper selected={!!selectedElements} multiSelect={props.multiSelect} height={(selectedElements && selectedElemetsListRef?.current) ? selectedElemetsListRef?.current?.getBoundingClientRect().height + 55 : 60}>
                {props.label &&
                    <LabelWithEllipsis>
                        {props.label}
                        {props.required &&
                            <MandatoryFieldStar />
                        }
                    </LabelWithEllipsis>
                }
                <TextComponent
                    type="Input"
                    disabled={props.disabled}
                    value={inputValue || ''}
                    onChange={changeInput}
                    onBlur={props.onBlur}
                    placeholder={props.placeholder || ''}
                    error={props.error || blurError}
                    errorMsg={props.errorMsg || ''}
                    onClick={props.disableOnClick ? undefined : onClick}
                />
                {selectedElements &&
                    <SelectedElemsList ref={selectedElemetsListRef} withLabel={!!props.label}>
                        {(selectedElements || [])?.map(elem =>
                            <>
                                {elem &&
                                    <>
                                        {elem.data?.type === 'default' ? (
                                            <Pill
                                                type="default"
                                                key={elem.key}
                                                text={`${elem.data?.Name || elem.data?.name || elem.data?.firstName} ${elem.data?.lastName ? elem?.data?.lastName : ''}`}
                                                disabled={props.disabled}
                                                elemId={elem.data?.id}
                                                onClick={removeElement}
                                            />
                                        ) : (
                                            <Pill
                                                type={elem?.data?.type === 'Personal' ? IProfileType.Personal : (elem?.data?.type === 'Group' ? IProfileType.Group : IProfileType.Company)}
                                                key={elem?.key}
                                                picture={elem?.data?.picture || elem?.data?.Picture || undefined}
                                                firstName={elem?.data?.Name || elem?.data?.name || elem?.data?.firstName}
                                                lastName={elem?.data?.lastName}
                                                disabled={props.disabled}
                                                id={elem?.data?.id}
                                                elemId={elem?.data?.id}
                                                onClick={removeElement}
                                            />
                                        )}
                                    </>
                                }
                            </>
                        )}
                    </SelectedElemsList>
                }
            </TextWrapper>
            <SearchResult withLabel={!!props.label}>
                {dropdownOpen &&
                    <List>
                        {!loading ? (
                            <>
                                {searchResults?.length > 0 ? (
                                    <Listbox height={searchResults?.length * 48}>
                                        <Scrollbars autoHide>
                                            {searchResults?.map(elem =>
                                                <button type="button" data-lc="js-lc-search-user" key={elem.key} onClick={(e) => selectElement(e, elem)}>
                                                    {!props.withoutAvatar && <Avatar
                                                        picture={elem?.data?.picture || elem?.data?.Picture}
                                                        firstName={elem.text}
                                                        lastName={''}
                                                        id={elem?.data?.id}
                                                        disabled={props.disabled}
                                                        type={(elem?.data?.dataType === 'Company' || elem?.data?.dataType === 'LegalEntity') ? IProfileType.Company : (elem?.data?.dataType === 'Cluster' ? IProfileType.Cluster : IProfileType.Personal)}
                                                        size={{
                                                            width: 24,
                                                            height: 24
                                                        }}
                                                    />}
                                                    <span>{elem.text}</span>
                                                </button>
                                            )}
                                        </Scrollbars>
                                    </Listbox>
                                ) : <NoDataWrapper>
                                    <NoData>
                                        <FormattedMessage id="global.noResult" />
                                    </NoData>
                                </NoDataWrapper>
                                }
                            </>
                        ) : (
                            <Loader>
                                <Spinner size="small" />
                            </Loader>
                        )}

                        {(!props.hideButtons && !loading) &&
                            <>
                                {props.buttonsType === ButtonsTypes.All
                                    ? <DoubleCreationWrapper>
                                        <SingleCreateWrapper onClick={userFormHandler}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                            <FormattedMessage id="searchInput.button.createPerson" />
                                        </SingleCreateWrapper>
                                        <SingleCreateWrapper onClick={companyFormHandler}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                            <FormattedMessage id="searchInput.button.createLegalEntity" />
                                        </SingleCreateWrapper>
                                    </DoubleCreationWrapper>
                                    :
                                    <>
                                        {props.buttonsType === ButtonsTypes.Person &&
                                            <AddingButton onClick={userFormHandler}>
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                                <FormattedMessage id="searchInput.button.createPerson" />
                                            </AddingButton>
                                        }
                                        {(props.buttonsType === ButtonsTypes.Company) &&
                                            <AddingButton onClick={companyFormHandler}>
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                                <FormattedMessage id="searchInput.button.createLegalEntity" />
                                            </AddingButton>
                                        }
                                    </>
                                }
                            </>
                        }
                    </List>
                }
            </SearchResult>
            <NewCompanyForm
                isVisible={showCompanyForm}
                closeHandler={companyFormHandler}
                initialData={{
                    name: inputValue
                }}
                returnData={selectElement}
            />
            <NewUserForm
                isVisible={showUserForm}
                closeHandler={userFormHandler}
                initialData={{
                    firstName: inputValue?.split(/ (.*)/)?.[0] || '',
                    lastName: inputValue?.split(/ (.*)/)?.[1] || ''
                }}
                returnData={selectElement}
            />
        </Wrapper>
    );
};