import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

import { instanceConfig } from '../../instance';
import * as Components from '../Messaging/Components';
import { colorStack } from '../../styleHelpers/colors';
import { fontSize } from '../../styleHelpers/fontSizes';
import { IClusterSingleMessage } from '../../entities/IClusters';

const { enableTracking } = instanceConfig;

// tslint:disable-next-line: no-var-requires
const withAITracking = enableTracking && require('react-appinsights').withAITracking;

type Alignment = 'right' | 'left';

interface IContainerProps {
    shadow?: boolean;
    align?: Alignment;
}

const Container = styled(Components.ContextMenuContainer) <IContainerProps>`
    position: absolute;
    background: ${colorStack.white};
    padding: 0;
    top: 20px;
    width: 110px;
    font-size: ${fontSize[13]};
    ${(props) => props.shadow && css`
        box-shadow: 0 0 3px 0 ${rgba(colorStack.black, 0.2)};
    `}
    ${({ align = 'right' }) => align === 'right' ? css`
        right: 0;
        left: auto;
    ` : css`
        right: auto;
        left: 0;
    `}
    > ul {
        width: 100%;
        li:first-child {
            border-bottom: 1px solid ${colorStack.ligthGrey}
        }
        li {
            display: flex;
            cursor: pointer;
            margin: 0;
            align-items: center;
            margin: 0;
            padding: 0.5rem 1rem;
            span {
                font-weight: 500;
            }
            svg {
                margin: 0 0.5rem 0 0;
            }
            &:hover {
                background-color: ${colorStack.whiteGrey};
            }
        }
    }
`;

interface IThreadMessages {
    messaging: IClusterSingleMessage;
}

type MessagestMenuProps = {
    shadow?: boolean;
    align?: Alignment;
    messageId: string;
    organizationName: string;
    clusterId: string | number;
    messageView: boolean;
    icon: IconDefinition;
    enableFullView: boolean;
    removeMessage(messageId: string);
};

class MessagestMenu extends React.Component<MessagestMenuProps & IThreadMessages> {

    removeHandler = () => {
        this.props.removeMessage(this.props.messageId);
    }

    render() {
        return (
            <Container shadow={this.props.shadow} align={this.props.align}>
                <ul>
                    {this.props.enableFullView &&
                        <li>
                            <span>
                                <Link to={`/orgs/${this.props.organizationName}/cluster/${this.props.clusterId}/activity/${this.props.messageId}`} className="date">
                                    <FormattedMessage id="global.fullView" />
                                </Link>
                            </span>
                        </li>
                    }
                    {this.props.messaging?.canDelete !== false &&
                        <li onClick={this.removeHandler}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                            <span className="name">
                                <FormattedMessage id="global.delete" />
                            </span>
                        </li>
                    }
                </ul>
            </Container>
        );
    }
}

export default instanceConfig.enableTracking ? withAITracking(MessagestMenu) : MessagestMenu;