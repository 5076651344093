import { EditorState, convertFromRaw, Modifier } from 'draft-js';

export const hasRichTextContent = (stateJson: string) => {
    let newEditorState;
    try {
        newEditorState = EditorState.set(EditorState.createEmpty(),
            {
                currentContent: convertFromRaw(JSON.parse(stateJson))
            });
    } catch (e) {
        return false;
    }

    return newEditorState?.getCurrentContent()?.hasText();
};

export const clearEditorContent = (editorState) => {
    const blocks = editorState
        .getCurrentContent()
        .getBlockMap()
        .toList();
    const updatedSelection = editorState.getSelection().merge({
        anchorKey: blocks.first().get('key'),
        anchorOffset: 0,
        focusKey: blocks.last().get('key'),
        focusOffset: blocks.last().getLength()
    });
    const newContentState = Modifier.removeRange(
        editorState.getCurrentContent(),
        updatedSelection,
        'forward'
    );
    return EditorState.push(editorState, newContentState, 'remove-range');
};