import moment from 'moment';
import { IntlShape } from 'react-intl';

import { Lcid, IClusterMessageTranslation } from '../entities/ILanguage';
import { IClusterSingleMessage } from '../entities/IClusters';
import { getData } from './date';

export const languageList = {
    1033: 'en',
    1036: 'fr',
    1045: 'pl',
    1034: 'es',
    1040: 'it',
    1049: 'ru',
    1058: 'uk',
    1031: 'de',
    1046: 'pt'
};

export const languageIcons = {
    [Lcid.En]: require('../../wwwroot/assets/images/icons/languages/en.png'),
    [Lcid.Fr]: require('../../wwwroot/assets/images/icons/languages/fr.png'),
    [Lcid.Es]: require('../../wwwroot/assets/images/icons/languages/es.png'),
    [Lcid.Pt]: require('../../wwwroot/assets/images/icons/languages/pt.png'),
    [Lcid.Ru]: require('../../wwwroot/assets/images/icons/languages/ru.png'),
    [Lcid.Uk]: require('../../wwwroot/assets/images/icons/languages/uk.png'),
    [Lcid.De]: require('../../wwwroot/assets/images/icons/languages/de.png'),
    [Lcid.Pl]: require('../../wwwroot/assets/images/icons/languages/pl.png'),
    [Lcid.Nl]: require('../../wwwroot/assets/images/icons/languages/nl.png'),
    [Lcid.It]: require('../../wwwroot/assets/images/icons/languages/it.png'),
    [Lcid.Ms]: require('../../wwwroot/assets/images/icons/languages/ms.png'),
    [Lcid.Vi]: require('../../wwwroot/assets/images/icons/languages/vi.png'),
    [Lcid.Hr]: require('../../wwwroot/assets/images/icons/languages/hr.png'),
    [Lcid.Hu]: require('../../wwwroot/assets/images/icons/languages/hu.png'),
    [Lcid.Sk]: require('../../wwwroot/assets/images/icons/languages/sk.png'),
    [Lcid.Sl]: require('../../wwwroot/assets/images/icons/languages/sl.png'),
    [Lcid.Ar]: require('../../wwwroot/assets/images/icons/languages/ar.png'),
    [Lcid.Ro]: require('../../wwwroot/assets/images/icons/languages/ro.png'),
    [Lcid.Fil]: require('../../wwwroot/assets/images/icons/languages/fil.png'),
    [Lcid.Th]: require('../../wwwroot/assets/images/icons/languages/th.png'),
    [Lcid.Da]: require('../../wwwroot/assets/images/icons/languages/da.png'),
    [Lcid.Fi]: require('../../wwwroot/assets/images/icons/languages/fi.png'),
    [Lcid.Id]: require('../../wwwroot/assets/images/icons/languages/id.png'),
    [Lcid.Ja]: require('../../wwwroot/assets/images/icons/languages/ja.png'),
    [Lcid.Tr]: require('../../wwwroot/assets/images/icons/languages/tr.png'),
    [Lcid.Zh]: require('../../wwwroot/assets/images/icons/languages/zh.png')
};

export const languageLabels = {
    [Lcid.All]: {
        id: 'global.language.all'
    },
    [Lcid.En]: {
        id: 'global.language.en'
    },
    [Lcid.Fr]: {
        id: 'global.language.fr'
    },
    [Lcid.Es]: {
        id: 'global.language.es'
    },
    [Lcid.Pt]: {
        id: 'global.language.pt'
    },
    /******* */
    [Lcid.Ru]: {
        id: 'global.language.ru'
    },
    [Lcid.Uk]: {
        id: 'global.language.uk'
    },
    [Lcid.De]: {
        id: 'global.language.de'
    },
    [Lcid.Pl]: {
        id: 'global.language.pl'
    },
    [Lcid.Nl]: {
        id: 'global.language.nl'
    },
    [Lcid.It]: {
        id: 'global.language.it'
    },
    [Lcid.Ms]: {
        id: 'global.language.ms'
    },
    [Lcid.Vi]: {
        id: 'global.language.vi'
    },
    [Lcid.Hr]: {
        id: 'global.language.hr'
    },
    [Lcid.Hu]: {
        id: 'global.language.hu'
    },
    [Lcid.Sk]: {
        id: 'global.language.sk'
    },
    [Lcid.Sl]: {
        id: 'global.language.sl'
    },
    [Lcid.Ar]: {
        id: 'global.language.ar'
    },
    [Lcid.Ro]: {
        id: 'global.language.ro'
    },
    [Lcid.Fil]: {
        id: 'global.language.fil'
    },
    [Lcid.Th]: {
        id: 'global.language.th'
    },
    [Lcid.Da]: {
        id: 'global.language.da'
    },
    [Lcid.Fi]: {
        id: 'global.language.fi'
    },
    [Lcid.Id]: {
        id: 'global.language.id'
    },
    [Lcid.Ja]: {
        id: 'global.language.ja'
    },
    [Lcid.Tr]: {
        id: 'global.language.tr'
    },
    [Lcid.Zh]: {
        id: 'global.language.zh'
    }
};

export const getCountryFromLanguageString = (langStr: string) => {
    const split = langStr.split('-');
    return (split[1] || split[0]).toUpperCase();
};

export const activityTranslations = (message: IClusterSingleMessage, clusterActivityTranslation: IClusterMessageTranslation, intl?: IntlShape): string => {
    let originalMessage = clusterActivityTranslation?.[message.translationKey];
    const isTranslations = originalMessage?.includes('translations');
    if (isTranslations) {
        const extractedFielsdArray = originalMessage?.split('{').filter(elem => elem.includes('translations')).map(el => el.split('.')[1].split('}')[0]);
        for (let i = 0; i < extractedFielsdArray?.length; i++) {
            originalMessage = originalMessage?.replace(`{translations.${extractedFielsdArray[i]}}`, intl.formatMessage({id: message?.additionalInformation.translations[extractedFielsdArray[i]]}));
        }
    }
    return originalMessage?.
        replace('{memberA.firstName}', message?.additionalInformation?.memberA?.firstName).
        replace('{memberA.lastName}', message?.additionalInformation?.memberA?.lastName).
        replace('{memberB.firstName}', message?.additionalInformation?.memberB?.firstName).
        replace('{memberB.lastName}', message?.additionalInformation?.memberB?.lastName).
        replace('{member.lastName}', message?.additionalInformation?.member?.lastName).
        replace('{member.firstName}', message?.additionalInformation?.member?.firstName).
        replace('{member.firstName}', message?.additionalInformation?.member?.firstName).
        replace('{firstName}', message?.sender?.firstName).
        replace('{lastName}', message?.sender?.lastName).
        replace('{folderName}', message?.additionalInformation?.folderName).
        replace('{fileName}', message?.additionalInformation?.sourceName ? message?.additionalInformation?.sourceName : message?.additionalInformation?.fileName).
        replace('{folder}', message?.additionalInformation?.isDeletedPermanently ? message?.additionalInformation?.path : message?.additionalInformation?.sourcePath
        ? message?.additionalInformation?.sourcePath : Array.isArray(message.additionalInformation) ? message?.additionalInformation?.[0]?.path
        : message?.additionalInformation?.folder ? message?.additionalInformation?.folder : message?.additionalInformation?.path).
        replace('{invoker.firstName}', message?.additionalInformation?.invoker?.firstName).
        replace('{invoker.lastName}', message?.additionalInformation?.invoker?.lastName).
        replace('{validator.firstName}', message?.additionalInformation?.validator?.firstName).
        replace('{validator.lastName}', message?.additionalInformation?.validator?.lastName).
        replace('{date}', moment.utc(message?.additionalInformation?.date).local().format('DD MMM YYYY HH:mm:ss')).
        replace('{newTitle}', getData(message?.additionalInformation?.newTitle)).
        replace('{involvedPartyName}', getData(message?.additionalInformation?.involvedPartyName)).
        replace('{involvedPartiesGroupName}', getData(message?.additionalInformation?.involvedPartiesGroupName)).
        replace('{timelineTitle}', getData(message?.additionalInformation?.timelineTitle)).
        replace('{legalStakeTitle}', getData(message?.additionalInformation?.legalStakeTitle)).
        replace('{economicStakeTitle}', getData(message?.additionalInformation?.economicStakeTitle)).
        replace('{connectedMember.lastName}', message?.additionalInformation?.connectedMember?.lastName).
        replace('{connectedMember.firstName}', message?.additionalInformation?.connectedMember?.firstName).
        replace('{command.validationContext.type}', getData(message?.additionalInformation?.command?.validationContext?.Type)).
        replace('{filesNames}', Array.isArray(message?.additionalInformation?.filesNames) ? message?.additionalInformation?.filesNames?.map((file) => file.sourceName)?.join(', ')
        : Array.isArray(message?.additionalInformation) ? message?.additionalInformation?.map(({fileName}) => fileName)?.join(', ') : message?.additionalInformation?.filesNames?.join(', '));
};
