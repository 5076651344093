import React, { Component, RefObject, createRef, Children, PropsWithChildren, ReactNode } from 'react';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';
import styled, { css } from 'styled-components';

import { Loader } from '../../../components/Common/Loader/Loader';

interface ICustomScrollWrapper {
    height?: string;
}

export const MessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const CustomScrollWrapper = styled.div`
    height: ${(props: ICustomScrollWrapper) => props.height || '100%'};
    overflow: auto;

    .ms-Spinner {
        margin: 1rem 0;
    }
`;

const CustomScrollbars = styled(Scrollbars) <{ $hideScrollbar?: boolean, $noRightMargin?: boolean }>`
    div:first-of-type {
        right: -5px;
    }
    ${props => props.$noRightMargin && css`
        >div:first-child {
            margin-right: 0 !important;
        }
    `}
    ${props => props.$hideScrollbar && css`
        >div:last-child {
            display: none
        }
    `}
`;

interface IScrollBoxProps {
    hasMore: boolean;
    autoHide?: boolean;
    loadingLabel?: ReactNode;
    heigth?: string;
    isReverse?: boolean;
    pageStart?: number;
    innerRef?: React.RefObject<HTMLDivElement>;
    additionalBottomElement?: ReactNode;
    additionalTopElement?: ReactNode;
    className?: string;
    withoutChildrenCount?: boolean;
    hideScrollbar?: boolean;
    resetPage?: boolean;
    noRightMargin?: boolean;
    children?: ReactNode;
    onScrollResults(page: number): void;
    getRef?(scrollRef: Scrollbars): void;
    hideBreadcrumbs?(hideBreadcrumbs: boolean);
}

interface IScrollBoxState {
    page: number;
    lastPage: number;
}

export default class ScrollBox extends Component<IScrollBoxProps, IScrollBoxState> {
    topRef: RefObject<HTMLDivElement> = createRef();
    bottomRef: RefObject<HTMLDivElement> = createRef();
    rootRef: RefObject<HTMLDivElement> = createRef();
    observer: IntersectionObserver;

    constructor(props: IScrollBoxProps) {
        super(props);
        const initialPage = props.pageStart || 0;

        this.state = {
            page: initialPage,
            lastPage: initialPage - 1
        };
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleObserver, {
            root: this.rootRef.current,
            rootMargin: '0px',
            threshold: 0
        });

        this.observer.observe(this.props.isReverse ? this.topRef.current : this.bottomRef.current);
    }

    componentDidUpdate(prevProps: PropsWithChildren<IScrollBoxProps>) {
        const { withoutChildrenCount, resetPage, children } = this.props;

        if (!withoutChildrenCount && (Children.count(prevProps.children) < Children.count(children))) {
            this.setState(currentState => ({ page: currentState.page + 1 }));
        }

        if (withoutChildrenCount && resetPage && this.state.page > 0) {
            this.setState({
                page: 0,
                lastPage: -1
            });
        }
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    handleObserver = (entries: IntersectionObserverEntry[]) => {
        const { onScrollResults, withoutChildrenCount, hasMore, resetPage } = this.props;
        const { page, lastPage } = this.state;

        if (!withoutChildrenCount && entries[0].isIntersecting && lastPage < page) {
            this.setState(currentState => ({
                lastPage: currentState.page
            }), () => {
                onScrollResults(page + 1);
            });
        } else if (entries[0].isIntersecting && withoutChildrenCount && hasMore && !resetPage) {
            this.setState(currentState => ({
                page: currentState.page + 1
            }), () => {
                onScrollResults(page + 1);
            });
        }
    }

    assigneRef = (ref: Scrollbars) => {
        const { getRef } = this.props;
        !!getRef && getRef(ref);
    }

    moveBreadcrumbs = (frame: positionValues) => {
        this.props.hideBreadcrumbs(!!frame.scrollTop);
    }

    render() {
        const { children, hasMore, loadingLabel, heigth, isReverse, additionalBottomElement, additionalTopElement, autoHide, innerRef, className, hideScrollbar, noRightMargin } = this.props;

        return (
            <CustomScrollWrapper height={heigth} ref={this.rootRef} className={className || ''}>
                <CustomScrollbars onScrollFrame={this.props.hideBreadcrumbs ? this.moveBreadcrumbs : undefined} ref={this.assigneRef} autoHide={autoHide} $hideScrollbar={hideScrollbar} $noRightMargin={noRightMargin}>
                    <MessagesWrapper className="scroll-wrapper" ref={innerRef}>
                        <div ref={this.topRef}>
                            {hasMore && isReverse && (
                                <Loader loading={true} label={loadingLabel || ''} />
                            )}
                        </div>
                        {additionalTopElement}
                        {children}
                        {additionalBottomElement}
                        <div ref={this.bottomRef}>
                            {hasMore && !isReverse && (
                                <Loader loading={true} label={loadingLabel || ''} />
                            )}
                        </div>
                    </MessagesWrapper>
                </CustomScrollbars>
            </CustomScrollWrapper>
        );
    }
}
