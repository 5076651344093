import * as diacritics from 'diacritics';

import { toValidLink } from './links';

export const removeLeadingZero = (size: string) => size.replace(/\b0+/g, '');

export const splitUpperCase = (data: string) => data.match(/[A-Z][a-z]+/g).join(' ');

export const clearStringForSearch = (str: string = ''): string => diacritics
    .remove(`${str}`.trim())
    .toLowerCase()
    .replace(/\\/g, '')
    .replace(/\s/g, '')
    .replace(/\(|\)|\[|\]/g, '');

export const linkify = (content: string) => {
    const regEx = /(?:((\b(https?|ftp|file):\/\/)[\S]+(\b|$))|(^|[^\/])(www\.[\S]+(\b|$)))/igm;
    const urls = content.match(regEx);
    if (urls) {
        urls.forEach((url) => {
            content = content.replace(url, `<a target="_blank" href="${toValidLink(url.trim())}">${url}</a>`);
        });
    }
    return content;
};

export const hasJsonStructure = (content: string) => {
    if (typeof content !== 'string') return false;
    try {
        const result = JSON.parse(content);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]' || type === '[object Array]';
    } catch (err) {
        return false;
    }
};

export const textTruncate = (name: string, characters = 10) => {
    return name?.length > characters ? `${name?.substring(0, characters)}...` : name;
};

export const textContentFromHtmlString = (html: string): string => {
    try {
        const htmlElement = document.createElement('div');
        htmlElement.innerHTML = html;
        return htmlElement.innerText;
    } catch (error) {
        return undefined;
    }
};

export const convertFromBinary = (stringToConvert: string) => {
    const binary = atob(stringToConvert);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
};

export const convertToBinary = (stringToConvert: string) => {
    const codeUnits = new Uint16Array(stringToConvert.length);
    for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = stringToConvert.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
};

export const camelize = (str: string) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
};

export const convertPascalCaseKeyToCamelCaseKey = (str: string) => {
    const chunks = str.split('.');

    let transformedList = chunks.map(c => {
        return camelize(c);
    });

    return transformedList.join('.');
};