import React, { useState, useEffect, useCallback, FunctionComponent, FC } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { IClusterStakeProperty, StakeDetailsSubType } from '../../../../../entities/IClusterStake';
import { ErrorText } from './StakeStyles';
import { ProfilesSummary } from '../../../../Common/ProfilesSummary/ProfilesSummary';
import { IValue } from '../../../../../entities/IPickers';
import { UsersAndLegalEntitiesPicker } from '../../../../Common/Pickers/UsersAndLegalEntitiesPicker';
import { IProfileType } from '../../../../../entities/IGlobal';

const Wrapper = styled.div`
    span {
        overflow-wrap: break-word;
    }
`;

const PickerWrapper = styled.div`
    margin-top: 6px;
`;

interface IPartyContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    context?: 'PLE' | 'Cluster';
    setEditingData(propertie: IClusterStakeProperty);
}

type IPropsWithRouterAndInt = RouteComponentProps<{ organization: string }> & IPartyContentProps;

export const PartyContent: FC<IPartyContentProps> = withRouter<IPropsWithRouterAndInt, FunctionComponent<IPropsWithRouterAndInt>>(({ match, error, data, editStake, propertieId, context, setEditingData }) => {
    const [content, setContent] = useState<IValue[]>(undefined);
    const LegalEntity = 'legalentity';
    const Person = 'Person';
    useEffect(() => {
        const finalData = Array.isArray(data?.value) ? data?.value : [data?.value];
        setContent(finalData?.map(elem => elem ? ({
            key: elem.id,
            text: elem.Name || elem.name,
            data: elem
            // tslint:disable-next-line:no-null-keyword
        }) : null) || []);
    }, [data, editStake]);

    const setFieldValue = useCallback((value: IValue[]) => {
        setContent(value);
        setEditingData({
            ...data,
            value: value?.length > 0 ? ({
                text: value?.[0]?.text,
                emailContact: value?.[0]?.data?.emailContact,
                id: value?.[0]?.data?.id,
                name: value?.[0]?.data ? (value?.[0]?.data?.Name || value?.[0]?.data?.name || `${value?.[0]?.data?.firstName} ${value?.[0]?.data?.lastName}`) : undefined,
                picture: value?.[0]?.data?.picture || value?.[0]?.data?.Picture,
                subTitle: '',
                cabinet: value?.[0].data?.cabinet,
                jobTitle: value?.[0].data?.jobTitle,
                type: value?.[0]?.data?.dataType || value?.[0]?.data?.type || 'Personal'
                // tslint:disable-next-line:no-null-keyword
            }) : null,
            id: propertieId
        });
    }, [propertieId, data, setEditingData]);

    const getData = (properties: any) => {
        setEditingData({
            ...data,
            value: properties,
            id: propertieId
        });
    };
    return (
        <Wrapper>
            {editStake ? (
                <>
                    <PickerWrapper>
                        <UsersAndLegalEntitiesPicker
                            value={content}
                            onSelectElement={setFieldValue}
                            getSaveData={getData}
                            filteredCompanies={data.subType === StakeDetailsSubType.FilteredByOurGroup}
                            allCompanies={data.subType !== StakeDetailsSubType.FilteredByOurGroup}
                            hideButtons={data.subType === StakeDetailsSubType.FilteredByOurGroup}
                        />
                    </PickerWrapper>
                    {error && <ErrorText>{error}</ErrorText>}
                </>
            ) : (
                <>
                    {(!!content && content?.[0]?.data) &&
                        <ProfilesSummary
                            organizationUrlName={match.params.organization}
                            key={content?.[0]?.data?.id}
                            type={content?.[0]?.data?.type?.toLocaleLowerCase() === LegalEntity ? IProfileType.Company : content?.[0]?.data?.type?.toLocaleLowerCase() || content?.[0]?.data?.DataType && (content?.[0]?.data?.DataType === Person ? IProfileType.Personal : IProfileType.Company)}
                            profile={content?.[0]?.data ? {
                                picture: content?.[0]?.data?.picture || content?.[0]?.data?.Picture,
                                firstName: content?.[0]?.data?.name || content?.[0]?.data?.Name,
                                id: content?.[0]?.data?.id,
                                jobTitle: content?.[0]?.data?.subTitle
                            } : undefined}
                        />
                    }
                </>
            )}
        </Wrapper>
    );
}) as any;
