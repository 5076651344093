import React, { FC, useCallback, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { sizes } from '../../../styleHelpers/breakpoint';
import boxShadow from '../../../styleHelpers/mixins/shadow';
import { Avatar } from '../Avatar/Avatar';
import { IProfileType } from '../../../entities/IGlobal';

const PersonRow = styled.div<{ fullWidth?: boolean; onlyAvatar?: boolean }>`
    ${props => (!props.fullWidth && !props.onlyAvatar) && css`
        padding: 0.4rem;
        margin-bottom: 2px;
    `}
    display: flex;
    align-items: center;
    a {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        ${props => !props.onlyAvatar && css`
            margin-right: 2rem;
        `}
    }
`;

const UserImage = styled.div<{ onlyAvatar?: boolean }>`
    margin-right: 0.5rem;
    ${props => props.onlyAvatar && css`
        margin-right: 1rem;
    `}
`;

const PersonName = styled.div`
    font-weight: 600;
    color: ${colorStack.label};
    margin: 0 1rem 0 0;
    font-size: ${fontSize[13]};
`;

const Tooltip = styled.div<{ position: 'left' | 'right', top: number, left: number }>`
    ${boxShadow()};
    position: fixed;
    border: 1px solid ${colorStack.ligthGrey};
    ${props => props.top && css`
        top: ${`${props.top + 40}px`};
    `};
    ${props => (props.left && props.position === 'left') && css`
        left: ${`${props.left}px`};
    `};
    background: ${colorStack.white};
    padding: 1rem;
    width: 350px;
    z-index: 99;
    ${props => (props.left && props.position === 'right') && css`
        left: ${`${props.left - 350}px`};
    `}
`;

const TopSection = styled.div`
    display: flex;
`;

const Meta = styled.div`
    display: flex;
    flex-direction: column;
    margin: .5rem 0 0 0;
    span {
        font-size: ${fontSize[10]};
        color: ${colorStack.disabled};
        font-weight: 400;
    }
    a {
        text-decoration: none;
    }
`;

interface IProfilesSummaryProps {
    type: IProfileType;
    profile: {
        picture: string;
        firstName: string;
        id: string;
        lastName?: string;
        jobTitle?: string;
        department?: {
            name: string;
            id: string;
        };
        cabinet?: any;
    };
    email?: string;
    fullWidth?: boolean;
    onlyAvatar?: boolean;
    organizationUrlName?: string;
    preventRedirection?: boolean
}

interface IProfilesSummaryDetailsProps {
    showTooltip: boolean;
    tooltipTop: number;
    tooltipLeft: number;
    tooltipPosition: 'left' | 'right';
}

const ProfileSummaryDetails: FC<IProfilesSummaryProps & IProfilesSummaryDetailsProps> = props => {
    const { showTooltip, tooltipTop, tooltipLeft, tooltipPosition } = props;

    return (
        <>
            <UserImage onlyAvatar={props.onlyAvatar}>
                <Avatar
                    picture={props.profile?.picture}
                    firstName={props.profile?.firstName}
                    lastName={props.profile?.lastName}
                    id={props.profile?.id}
                    type={props.type}
                    preventRedirection={props?.preventRedirection}
                    extended={!props.onlyAvatar}
                    organizationUrlName={props.organizationUrlName}
                />
            </UserImage>
            {showTooltip &&
                <Tooltip position={tooltipPosition} top={tooltipTop} left={tooltipLeft}>
                    <TopSection>
                        <UserImage>
                            <Avatar
                                picture={props.profile?.picture}
                                firstName={props.profile?.firstName}
                                lastName={props.profile?.lastName}
                                id={props.profile?.id}
                                type={props.type}
                                size={{ width: 64, height: 64 }}
                                organizationUrlName={props.organizationUrlName}
                            />
                        </UserImage>
                        <PersonName>
                            {props.profile?.firstName} {props.profile?.lastName}
                            <Meta>
                                {props.email && <a href={`mailto:${props.email}`}><span><FontAwesomeIcon icon={faEnvelope} /> {props.email}</span></a>}
                                <span>{props.profile?.jobTitle}</span>
                                <span>{props.profile?.department?.name}</span>
                                <span>{props.profile?.cabinet?.name || props.profile?.cabinet}</span>
                            </Meta>
                        </PersonName>
                    </TopSection>
                </Tooltip>
            }
        </>
    );
};

export const ProfilesSummary: FC<IProfilesSummaryProps> = props => {
    const personRowRef = useRef<HTMLDivElement>(undefined);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [tooltipTop, setTooltipTop] = useState<number>(0);
    const [tooltipLeft, setTooltipLeft] = useState<number>(0);
    const [tooltipPosition, setTooltipPosition] = useState<'left' | 'right'>('left');
    const [targetType, setTargetType] = useState<string>(undefined);

    useEffect(() => {
        setTargetType(IS_TEAMS_INSTANCE ? '_self' : '_blank');
    }, [IS_TEAMS_INSTANCE]);

    const hoverHandler = useCallback(() => {
        const windowWidth = window.innerWidth;
        const elemOffsetLeft = personRowRef.current.getBoundingClientRect().left;
        const elemOffsetTop = personRowRef.current.getBoundingClientRect().top;
        const elemWidth = personRowRef.current.clientWidth;
        const popupWidth = 350;
        const mainWrapperMargin = windowWidth > sizes.desktop ? 80 : 16;
        setTooltipTop(elemOffsetTop);
        setTooltipLeft(elemOffsetLeft);

        if (windowWidth > elemOffsetLeft + elemWidth + popupWidth + mainWrapperMargin) {
            setTooltipPosition('left');
        } else {
            setTooltipPosition('right');
        }
        setShowTooltip(tooltipVisible => !tooltipVisible);
    }, []);

    return (
        <>
            {props.profile &&
                <PersonRow fullWidth={props.fullWidth} onlyAvatar={props.onlyAvatar} ref={personRowRef}>
                    <div
                        onMouseEnter={hoverHandler}
                        onMouseLeave={hoverHandler}
                    >
                        <ProfileSummaryDetails
                            type={props?.type}
                            organizationUrlName={props.organizationUrlName}
                            onlyAvatar={props?.onlyAvatar}
                            preventRedirection={props?.preventRedirection}
                            fullWidth={props?.fullWidth}
                            profile={{
                                picture: props?.profile?.picture,
                                id: props?.profile?.id,
                                firstName: props?.profile?.firstName,
                                jobTitle: props?.profile?.jobTitle,
                                lastName: props?.profile?.lastName,
                                department: props?.profile?.department,
                                cabinet: props?.profile?.cabinet
                            }}
                            email={props.email}
                            showTooltip={showTooltip}
                            tooltipTop={tooltipTop}
                            tooltipLeft={tooltipLeft}
                            tooltipPosition={tooltipPosition}
                        />
                    </div>
                </PersonRow>
            }
        </>
    );
};