import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div<{ directionBottom?: boolean, fixedWidth: string }>`
    position: relative;
    z-index: 999;
    ${props => props.fixedWidth && css`
        width: ${`${props.fixedWidth}px`};
    `}
    > div {
        cursor: pointer;
        margin: 0 .5rem;
        & + div {
            display: none;
        };
        &:hover + div {
            display: inline-table;
            margin-left: 2rem;
            ${props => props.directionBottom && css`
                top:0;
                bottom: auto;
            `};
        };
    };
`;

const WrapperWithoutIco = styled.div<{ directionBottom?: boolean, fixedWidth: string }>`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    ${props => props.fixedWidth && css`
        width: ${`${props.fixedWidth}px`};
    `}
    > div {
        display: none;
        left: 0;
        white-space: nowrap;
        right: 0;
        margin: auto;
        ${props => props.directionBottom && css`
            top: 100%;
            bottom: auto;
        `};
    }
    &:hover {
        > div {
            display: inline-table;
        }
    }
`;

const Content = styled.div`
    color: ${colorStack.content};
    position: absolute;
    bottom: 90%;
    background: ${colorStack.whiteBlue};
    font-size: ${fontSize[13]};
    border-radius: 8px;
    z-index: 999999;
    border: 1px solid ${colorStack.middleBlue};
    padding: .5rem;
    width: auto;
    z-index: 1;
`;

interface Props {
    children: React.ReactNode;
    withIco?: boolean;
    directionBottom?: boolean
    fixedWidth?: string;
}

export const Tooltip: FC<Props> = props => {
    return (
        <>
            {props.withIco ? (
                <Wrapper directionBottom={props.directionBottom} fixedWidth={props.fixedWidth}>
                    <div>
                        <FontAwesomeIcon icon={faCircleInfo} />
                    </div>
                    <Content>
                        {props.children}
                    </Content>
                </Wrapper>
            ) : (
                <WrapperWithoutIco directionBottom={props.directionBottom} fixedWidth={props.fixedWidth}>
                    <Content>
                        {props.children}
                    </Content>
                </WrapperWithoutIco>
            )}
        </>
    );
};
