import React, { useCallback, useState, useEffect, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { IDropdownOption, IPersonaProps } from '@fluentui/react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { ClusterPrivacy, IClusterPersonInCharge, ClusterVisibility, IContextTypes } from '../../../entities/IClusters';
import { Referentials, IProfileType, IContextList, EClusterStatus } from '../../../entities/IGlobal';
import { getOrganizationMembersSearch } from '../../../actions/organizationActions';
import { getReferentials, getReferentialsIdentities } from '../../../actions/globalActions';
import { getSearchResult } from '../../../actions/searchActions';
import { FacetsNames } from '../../../entities/ISearch';
import { IPresentation, PresentationEditField } from '../../../entities/CLE/ICLE';
import { StakeDetailsType } from '../../../entities/IClusterStake';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';
import { Tag } from '../TagPicker/Tag';
import { TagPickerColor, TagPicker, ITag } from '../TagPicker/TagPicker';
import { RichTextView } from '../RichText/RichTextView';
import { DebouncedPeoplePicker } from '../DebouncedPeoplePicker';
import { WhiteCollapsedSection } from '../WhiteCollapsedSection/WhiteCollapsedSection';
import { PresentationSection } from '../../Organization/ClustersV2/ClusterOverview/PresentationComponents';
import { SetPropertiesIcon } from '../../Organization/ClustersV2/ClusterOverview/StakeComponents/SetPropertiesIcon';
import { ProfilesSummary } from '../ProfilesSummary/ProfilesSummary';
import { clusterPrivacyTranslation, clusterVisibilityTranslation } from '../../../tools/translationTools';
import { ProfileDataType } from '../../../tools/profileConstants';
import { SelectInput } from '../../Common/SelectInput/SelectInput';
import { convertPascalCaseKeyToCamelCaseKey } from '../../../tools/string';
import { getStatusText } from '../../../tools/statusHelper';

type GetOrgMembers = ReturnType<typeof getOrganizationMembersSearch>;
type GetReferentialsIdentities = ReturnType<typeof getReferentialsIdentities>;
type GetReferentials = ReturnType<typeof getReferentials>;
type GetSearchResult = ReturnType<typeof getSearchResult>;

const Wrapper = styled.div<{ pageBreakOn?: boolean; }>`
    ${props => props.pageBreakOn && css`
        page-break-inside:avoid;
    `}
`;

const RowLeft = styled.div`
    font-size: ${fontSize[13]};;
    color: ${colorStack.disabled};
    width: 100%;
    font-weight: 500;
    margin: 0 0 1rem 0;
    ${media.desktop`
        width: 25%;
        margin: 0;
    `};
    svg, img {
        margin: 0 1rem 0 0;
        width: 16px!important;
    }
`;

const RowRight = styled.div`
    width: 100%;
    ${media.desktop`
        width: 75%;
    `}
`;

interface IPresentationProps {
    editMode: boolean;
    data: IPresentation;
    context: 'Cluster' | 'PLE';
    pageBreakOn?: boolean;
}

export const Presentation: FC<IPresentationProps> = props => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const formikContext = useFormikContext<any>();
    const [referentialsName, setReferentialsName] = useState<{ name: string, type: string, key: string }[]>(undefined);

    useEffect(() => {
        dispatch<GetReferentialsIdentities>(getReferentialsIdentities('')).then(res => {
            const ref1 = res.filter(elem => elem.referentialType === ProfileDataType.FirstReferential)[0];
            const ref2 = res.filter(elem => elem.referentialType === ProfileDataType.SecondReferential)[0];
            const ref3 = res.filter(elem => elem.referentialType === ProfileDataType.ThirdReferential)[0];
            setReferentialsName([{ name: ref1.name, type: ref1.referentialType, key: ref1.key }, { name: ref2.name, type: ref2.referentialType, key: ref2.key }, { name: ref3.name, type: ref3.referentialType, key: ref3.key }]);
        });
    }, [props.data?.organizationId]);

    const transformPickerResult = useCallback((profile: IClusterPersonInCharge): IPersonaProps => ({
        id: profile.id,
        text: `${profile.firstName} ${profile.lastName}`,
        imageInitials: `${(profile.firstName || '').slice(0, 1)}${(profile.lastName || '').slice(0, 1)}`,
        imageUrl: profile.picture,
        secondaryText: [profile.jobTitle, profile.cabinet].filter(val => val).join(' - ')
    }), []);

    const resolvePeople = useCallback(async (query: string = ''): Promise<IClusterPersonInCharge[]> => {
        let members = undefined;
        if (props.context === 'PLE') {
            members = await dispatch<GetSearchResult>(getSearchResult(query || '', 0, 20, Object.keys(FacetsNames).reduce((result, item) => {
                result[item] = [];
                return result;
            }, {})));
            return (members.items || []).map(({ firstName, lastName, jobTitle, picture, id }) => ({
                firstName: firstName, lastName: lastName, jobTitle: jobTitle, picture: picture, id
            }));
        } else {
            members = await dispatch<GetOrgMembers>(getOrganizationMembersSearch(props.data?.organizationId, query));
            return (members || []).map(({ firstName, lastName, jobTitle, department, picture, cabinet, id }) => ({
                firstName, lastName, jobTitle, department, picture, cabinet, id
            }));
        }
    }, [props.context]);

    const resolveReferentials = useCallback(async (type: Referentials, query: string): Promise<ITag[]> => {
        const referentials = await dispatch<GetReferentials>(getReferentials(query, type, IContextList.Platform));
        return referentials.map(ref => ({
            ...ref,
            value: ref.name
        }));
    }, []);

    return (
        <Wrapper pageBreakOn>
            <a data-scrollId="10" data-scrollName={intl.formatMessage({ id: 'cluster.label.presentation' })} />
            {props.data && (!props.editMode ? (
                <WhiteCollapsedSection
                    title={<FormattedMessage id="cluster.label.presentation" />}
                    editMode={props.editMode}
                >
                    {!!PresentationEditField[props.context].description && !!props.data?.description?.length && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={StakeDetailsType.Text} />
                                <FormattedMessage id="cluster.label.description" />
                            </RowLeft>
                            {!!props.data?.description && (
                                <RowRight>
                                    <RichTextView content={props.data.description || ''} />
                                </RowRight>
                            )}
                        </PresentationSection>
                    )}
                    {!!PresentationEditField[props.context].peopleInCharge && !!props.data?.peopleInCharge?.length && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={StakeDetailsType.Person} />
                                <FormattedMessage id="cluster.label.peopleincharge" />
                            </RowLeft>
                            {!!props.data?.peopleInCharge?.length && (
                                <RowRight>
                                    {(props.data.peopleInCharge || []).map(profile => (
                                        <ProfilesSummary key={profile.id} type={IProfileType.Personal} profile={profile} />
                                    ))}
                                </RowRight>
                            )}
                        </PresentationSection>
                    )}
                    {(!!props.data?.firstReferential && !!PresentationEditField[props.context].firstReferential && (!!props.data.firstReferential?.referentials?.length || !!props.data.firstReferential?.length)) && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                <span>
                                    {referentialsName?.[0]?.key !== undefined ?
                                        <FormattedMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[0]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                        : referentialsName?.[0]?.name
                                    }
                                </span>
                            </RowLeft>
                            <RowRight>
                                {(props.data.firstReferential?.referentials || props.data.firstReferential || []).map(referential => (
                                    <Tag tagColor={TagPickerColor.Expertise} key={referential.id} noShadow>
                                        {referential.name || referential.Name}
                                    </Tag>
                                ))}
                            </RowRight>
                        </PresentationSection>
                    )}
                    {(!!props.data?.secondReferential && !!PresentationEditField[props.context].secondReferential && !!props.data.secondReferential?.referentials?.length || !!props.data.secondReferential?.length) && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                <span>
                                    {referentialsName?.[1]?.key !== undefined ?
                                        <FormattedMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[1]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                        : referentialsName?.[1]?.name
                                    }
                                </span>
                            </RowLeft>
                            <RowRight>
                                {(props.data.secondReferential?.referentials || props.data.secondReferential || []).map(referential => (
                                    <Tag tagColor={TagPickerColor.Expertise} key={referential.id} noShadow>
                                        {referential.name || referential.Name}
                                    </Tag>
                                ))}
                            </RowRight>
                        </PresentationSection>
                    )}
                    {(!!props.data?.thirdReferential && !!PresentationEditField[props.context].thirdReferential && !!props.data.thirdReferential?.referentials?.length || !!props.data.thirdReferential?.length) && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                <span>
                                    {referentialsName?.[2]?.key !== undefined ?
                                        <FormattedMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[2]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                        : referentialsName?.[2]?.name
                                    }
                                </span>
                            </RowLeft>
                            <RowRight>
                                {(props.data.thirdReferential?.referentials || props.data.thirdReferential || []).map(referential => (
                                    <Tag tagColor={TagPickerColor.Expertise} key={referential.id} noShadow>
                                        {referential.name || referential.Name}
                                    </Tag>
                                ))}
                            </RowRight>
                        </PresentationSection>
                    )}
                </WhiteCollapsedSection>
            ) : (
                <>
                    <WhiteCollapsedSection
                        title={<FormattedMessage id="cluster.label.presentation" />}
                    >
                        {!!PresentationEditField[props.context].peopleInCharge &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={StakeDetailsType.Person} />
                                    <FormattedMessage id="cluster.label.peopleincharge" />
                                </RowLeft>
                                <RowRight>
                                    <DebouncedPeoplePicker
                                        noResultsFoundText={intl.formatMessage({ id: 'cluster.placeholder.noresult' })}
                                        transformResults={transformPickerResult}
                                        resolveQuery={resolvePeople}
                                        selectedItems={props.data?.peopleInCharge}
                                        onChange={(picker, result) => formikContext.setFieldValue(PresentationEditField[props.context].peopleInCharge, result)}
                                        itemLimit={15}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField.PLE.visibility && props.context === IContextTypes.PLE &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                    <FormattedMessage id="cluster.label.visibility" />
                                </RowLeft>
                                <RowRight>
                                    <SelectInput
                                        selectedKey={props.data?.visibility}
                                        onChange={(e, option: IDropdownOption) => formikContext.setFieldValue(PresentationEditField.PLE.visibility, option.key)}
                                        options={[{ key: 0, text: 'Visible' }, { key: 1, text: 'Hidden' }].map(elem => ({
                                            key: elem.key, text: clusterVisibilityTranslation(ClusterVisibility[elem.text], intl)
                                        }))}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField[props.context].status &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                    <FormattedMessage id="cluster.label.status" />
                                </RowLeft>
                                <RowRight>
                                    <SelectInput
                                        selectedKey={props.data?.status}
                                        onChange={(e, option: IDropdownOption) => formikContext.setFieldValue(PresentationEditField[props.context].status, option.key)}
                                        options={[EClusterStatus.Active, EClusterStatus.Closed, EClusterStatus.Archived].map(key => ({
                                            key, text: getStatusText(key, intl)
                                        }))}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField[props.context].privacy &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                    <FormattedMessage id="cluster.label.privacy" />
                                </RowLeft>
                                <RowRight>
                                    <SelectInput
                                        selectedKey={props.data?.privacy}
                                        onChange={(e, option: IDropdownOption) => formikContext.setFieldValue(PresentationEditField[props.context].privacy, option.key)}
                                        options={Object.keys(ClusterPrivacy).map(key => ({
                                            key, text: clusterPrivacyTranslation(ClusterPrivacy[key], intl)
                                        }))}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField[props.context].firstReferential &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                    <span>
                                        {referentialsName?.[0]?.key !== undefined ?
                                            <FormattedMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[0]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                            : referentialsName?.[0]?.name
                                        }
                                    </span>
                                </RowLeft>
                                <RowRight>
                                    <TagPicker
                                        resolveTags={query => resolveReferentials(Referentials.FirstReferential, query)}
                                        onChange={tags => formikContext.setFieldValue(PresentationEditField[props.context].firstReferential, props.context === 'PLE' ? tags.map(tag => ({
                                            ...tag,
                                            Name: tag.value
                                        })) : ({
                                            name: referentialsName?.[0]?.name || '',
                                            type: referentialsName?.[0]?.type || '',
                                            referentials: tags.map(tag => ({
                                                ...tag,
                                                id: tag.id,
                                                name: tag.value
                                            }))
                                        }))}
                                        selectedTags={(props.data?.firstReferential?.referentials || props.data?.firstReferential || []).map(referential => ({
                                            ...referential,
                                            value: referential.Name || referential.name
                                        }))}
                                        tagColor={TagPickerColor.LightBlue}
                                        onClearSearchBox={() => formikContext.setFieldValue(PresentationEditField[props.context].firstReferential, [])}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }

                        {!!PresentationEditField[props.context].secondReferential &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                    <span>
                                        {referentialsName?.[1]?.key !== undefined ?
                                            <FormattedMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[1]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                            : referentialsName?.[1]?.name
                                        }
                                    </span>
                                </RowLeft>
                                <RowRight>
                                    <TagPicker
                                        resolveTags={query => resolveReferentials(Referentials.SecondReferential, query)}
                                        onChange={tags => formikContext.setFieldValue(PresentationEditField[props.context].secondReferential, props.context === 'PLE' ? tags.map(tag => ({
                                            id: tag.id,
                                            Name: tag.value
                                        })) : ({
                                            name: referentialsName?.[1]?.name || '',
                                            type: referentialsName?.[1]?.type || '',
                                            referentials: tags.map(tag => ({
                                                id: tag.id,
                                                name: tag.value
                                            }))
                                        }))}
                                        selectedTags={(props.data?.secondReferential?.referentials || props.data?.secondReferential || []).map(referential => ({
                                            id: referential.id,
                                            value: referential.Name || referential.name
                                        }))}
                                        tagColor={TagPickerColor.LightBlue}
                                        onClearSearchBox={() => formikContext.setFieldValue(PresentationEditField[props.context].secondReferential, [])}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField[props.context].thirdReferential &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={StakeDetailsType.Reference} />
                                    <span>
                                        {referentialsName?.[2]?.key !== undefined ?
                                            <FormattedMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[2]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                            : referentialsName?.[2]?.name
                                        }
                                    </span>
                                </RowLeft>
                                <RowRight>
                                    <TagPicker
                                        resolveTags={query => resolveReferentials(Referentials.ThirdReferential, query)}
                                        onChange={tags => formikContext.setFieldValue(PresentationEditField[props.context].thirdReferential, props.context === 'PLE' ? tags.map(tag => ({
                                            id: tag.id,
                                            Name: tag.value
                                        })) : ({
                                            name: referentialsName?.[1]?.name || '',
                                            type: referentialsName?.[1]?.type || '',
                                            referentials: tags.map(tag => ({
                                                id: tag.id,
                                                name: tag.value
                                            }))
                                        }))}
                                        selectedTags={(props.data?.thirdReferential?.referentials || props.data?.thirdReferential || []).map(referential => ({
                                            id: referential.id,
                                            value: referential.Name || referential.name
                                        }))}
                                        tagColor={TagPickerColor.LightBlue}
                                        onClearSearchBox={() => formikContext.setFieldValue(PresentationEditField[props.context].thirdReferential, [])}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                    </WhiteCollapsedSection>
                </>
            ))}
        </Wrapper>
    );
};
