import * as React from 'react';
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor';
import { EditorState, convertFromRaw, convertToRaw, SelectionState, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import {
    ItalicButton, BoldButton, UnderlineButton, CodeButton, HeadlineOneButton, HeadlineTwoButton,
    HeadlineThreeButton, UnorderedListButton, OrderedListButton, BlockquoteButton
} from 'draft-js-buttons';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlaneTop, faXmark } from '@fortawesome/pro-solid-svg-icons';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { Spinner } from '../Spinner/Spinner';
import { colorStack } from '../../../styleHelpers/colors';

const EditorWrapper = styled.div<{ whiteBackground: boolean }>`
    box-sizing: border-box;
    cursor: text;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: ${colorStack.whiteGrey};
    ${props => props.whiteBackground && css`
        background: ${colorStack.white};
    `}
    .draftJsToolbar__toolbar__dNtBH {
        background: ${colorStack.white};
        border-radius: 8px;
        width: 100%;
        border: none;
        box-shadow: none;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 0;
    }
    .draftJsToolbar__button__qi1gf {
        color: #212529;
        cursor: pointer;
    }
    .draftJsToolbar__button__qi1gf svg {
        fill: #212529;
    }
    .DraftEditor-root {
        padding: 1rem;
        height: calc(100% - 34px)!important;
    }

    .DraftEditor-editorContainer {
        font-size: ${fontSize[20]};
        margin-bottom: 1rem;
        color: #242424;
    }
    .DraftEditor-root h1, .editor h1{
        font-size: 2rem;
    }
`;

const ActionButton = styled.div`
    cursor: pointer;
    width: 36px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        font-size: ${fontSize[20]};
    }
    &:hover {
        background: #f3f3f3;
    }
`;

const SpecialActionButtons = styled.div`
    margin: 0 0 0 auto;
    width: 72px;
    height: 34px;
    display: flex;
    justify-content: flex-end;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 1rem;
`;

interface IProps {
    initData: string;
    whiteBackground?: boolean;
    placeholder?: string;
    disableInitOnChange?: boolean;
    buttons?: { [key: string]: boolean };
    sendLoading?: boolean;
    clearText?: boolean;
    onChanged: (data: string, editorContent: any, hasText?: boolean) => void;
    sendHandler?(hasText?: boolean);
    onCancel?();
}

interface ITextEditorState {
    editorState: any;
    clearing: boolean;
}

export class RichTextEditor extends React.Component<IProps, ITextEditorState> {
    private editor;
    private staticToolbarPlugin;
    private linkifyPlugin;
    private plugins: any[];
    private onChangeDisabled = false;

    constructor(props: IProps) {
        super(props);

        this.staticToolbarPlugin = createToolbarPlugin();
        this.linkifyPlugin = createLinkifyPlugin();
        this.plugins = [
            this.staticToolbarPlugin,
            this.linkifyPlugin
        ];
        this.state = {
            editorState: createEditorStateWithText(''),
            clearing: false
        };
        this.onChangeDisabled = this.props.disableInitOnChange;
    }

    componentDidMount() {
        if (this.props.initData) {
            this.initData(this.props.initData, () => {
                !this.props.disableInitOnChange && this.props.onChanged(this.props.initData, this.state.editorState.getCurrentContent(), this.state.editorState.getCurrentContent().hasText());
            });
        } else {
            this.setState({
                editorState: EditorState.moveFocusToEnd(this.state.editorState)
            });
        }
    }

    componentDidUpdate() {
        if (this.props.clearText && !this.state.clearing) {
            this.setState({
                clearing: true
            }, () => {
                let { editorState } = this.state;
                let contentState = editorState.getCurrentContent();
                const firstBlock = contentState.getFirstBlock();
                const lastBlock = contentState.getLastBlock();
                const allSelected = new SelectionState({
                    anchorKey: firstBlock.getKey(),
                    anchorOffset: 0,
                    focusKey: lastBlock.getKey(),
                    focusOffset: lastBlock.getLength(),
                    hasFocus: true
                });
                contentState = Modifier.removeRange(contentState, allSelected, 'backward');
                editorState = EditorState.push(editorState, contentState, 'remove-range');
                this.setState({ editorState });
            });
        }
        if (!this.props.clearText && this.state.clearing) {
            this.setState({
                clearing: false
            });
        }
    }

    onChange = (editorState) => {
        const currentContentState = this.state.editorState.getCurrentContent();
        const newContentState = editorState.getCurrentContent();

        if (currentContentState !== newContentState) {
            this.setState({ editorState }, () => {
                !this.onChangeDisabled && this.props.onChanged(this.getData(), newContentState, newContentState.hasText());
                this.onChangeDisabled = false;
            });
        } else {
            this.setState({ editorState }, () => {
                !this.onChangeDisabled && this.props.onChanged(this.getData(), newContentState);
                this.onChangeDisabled = false;
            });
        }
    }

    // tslint:disable-next-line: no-empty
    initData = (stateJson: string, callback = () => { }) => {
        if (stateJson) {
            try {
                let state = convertFromRaw(JSON.parse(stateJson));
                if (state) {
                    let newState = EditorState.push(this.state.editorState, state);
                    this.setState({ editorState: EditorState.moveFocusToEnd(newState) }, callback);
                }
            } catch (e) {
                this.setState({
                    editorState: createEditorStateWithText(stateJson)
                });
            }
        }
    }

    getData = (): string => {
        let content = this.state.editorState.getCurrentContent();
        return JSON.stringify(convertToRaw(content));
    }

    render() {
        const { Toolbar } = this.staticToolbarPlugin;

        return (
            <EditorWrapper onClick={() => this.editor.focus()} whiteBackground={this.props.whiteBackground}>
                <Editor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    plugins={this.plugins}
                    ref={(element) => { this.editor = element; }}
                    placeholder={this.props.placeholder}
                />
                <Toolbar>
                    {externalProps => (
                        <ButtonsWrapper>
                            {!this.props.buttons ? (
                                <>
                                    <BoldButton {...externalProps} />
                                    <ItalicButton {...externalProps} />
                                    <UnderlineButton {...externalProps} />
                                    <CodeButton {...externalProps} />
                                    <Separator />
                                    <HeadlineOneButton {...externalProps} />
                                    <HeadlineTwoButton {...externalProps} />
                                    <HeadlineThreeButton {...externalProps} />
                                    <Separator />
                                    <UnorderedListButton {...externalProps} />
                                    <OrderedListButton {...externalProps} />
                                    <BlockquoteButton {...externalProps} />
                                    <SpecialActionButtons>
                                        {this.props.onCancel &&
                                            <ActionButton onClick={() => this.props.onCancel()}><FontAwesomeIcon icon={faXmark} /></ActionButton>
                                        }
                                        {this.props.sendHandler &&
                                            <ActionButton onClick={() => this.props.sendHandler(this.state.editorState.getCurrentContent().hasText())}>
                                                {this.props.sendLoading ? (
                                                    <Spinner size="small" />
                                                ) : (
                                                    <FontAwesomeIcon icon={faPaperPlaneTop} />
                                                )}
                                            </ActionButton>
                                        }
                                    </SpecialActionButtons>
                                </>
                            ) : (
                                <>
                                    {this.props.buttons?.boldButton && <BoldButton {...externalProps} />}
                                    {this.props.buttons?.italicButton && <ItalicButton {...externalProps} />}
                                    {this.props.buttons?.underlineButton && <UnderlineButton {...externalProps} />}
                                    {this.props.buttons?.codeButton && <CodeButton {...externalProps} />}
                                    {this.props.buttons?.h1Button || this.props.buttons?.h2Button || this.props.buttons?.h3Button &&
                                        <Separator />
                                    }
                                    {this.props.buttons?.h1Button && <HeadlineOneButton {...externalProps} />}
                                    {this.props.buttons?.h2Button && <HeadlineTwoButton {...externalProps} />}
                                    {this.props.buttons?.h3Button && <HeadlineThreeButton {...externalProps} />}
                                    {this.props.buttons?.pointListButton || this.props.buttons?.numberListButton || this.props.buttons?.blockquoteButton &&
                                        <Separator />
                                    }
                                    {this.props.buttons?.pointListButton && <UnorderedListButton {...externalProps} />}
                                    {this.props.buttons?.numberListButton && <OrderedListButton {...externalProps} />}
                                    {this.props.buttons?.blockquoteButton && <BlockquoteButton {...externalProps} />}
                                    {(this.props.buttons?.sendButton && this.props.sendHandler) &&
                                        <ActionButton onClick={() => this.props.sendHandler(this.state.editorState.getCurrentContent().hasText())}>
                                            {this.props.sendLoading ? (
                                                <Spinner size="small" />
                                            ) : (
                                                <FontAwesomeIcon icon={faPaperPlaneTop} />
                                            )}
                                        </ActionButton>
                                    }
                                </>
                            )}
                        </ButtonsWrapper>
                    )}
                </Toolbar>
            </EditorWrapper>
        );
    }
}